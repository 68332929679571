import { SweetAlertIcon } from "sweetalert2";

export class DialogModel {

    constructor(
        public readonly message: string,
        public readonly title: string,
        public readonly type: SweetAlertIcon,
        public readonly botaoConfirmar: string,
        public readonly botaoCancelar: string,

        ) {}

}