import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CampoCondominioComponent } from './campo-condominio/campo-condominio.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CampoBlocoComponent } from './campo-bloco/campo-bloco.component';
import { CampoIntegradoraComponent } from './campo-integradora/campo-integradora.component';
import { CampoModeloControladoraComponent } from './campo-modelo-controladora/campo-modelo-controladora.component';
import { CampoControladoraComponent } from './campo-controladora/campo-controladora.component';
import { CompoModeloLeitoraComponent } from './compo-modelo-leitora/compo-modelo-leitora.component';
import { CampoTipoAtendimentoComponent } from './campo-tipo-atendimento/campo-tipo-atendimento.component';
import { CampoRotaComponent } from './campo-rota/campo-rota.component';
import { CampoDispositivoComponent } from './campo-dispositivo/campo-dispositivo.component';
import { CampoPessoaFisicaComponent } from './campo-pessoa-fisica/campo-pessoa-fisica.component';
import { CampoTerceiroComponent } from './campo-terceiro/campo-terceiro.component';
import { CampoVeiculoComponent } from './campo-veiculo/campo-veiculo.component';
import { CampoCameraComponent } from './campo-camera/campo-camera.component';
import { CampoTipoDispositivoComponent } from './campo-tipo-dispositivo/campo-tipo-dispositivo.component';
import { CampoTimezoneComponent } from './campo-timezone/campo-timezone.component';
import { CampoTipoUnidadeComponent } from './campo-tipo-unidade/campo-tipo-unidade.component';

@NgModule({
  declarations: [
    CampoCondominioComponent,
    CampoBlocoComponent,
    CampoIntegradoraComponent,
    CampoModeloControladoraComponent,
    CampoControladoraComponent,
    CompoModeloLeitoraComponent,
    CampoTipoAtendimentoComponent,
    CampoRotaComponent,
    CampoDispositivoComponent,
    CampoPessoaFisicaComponent,
    CampoTerceiroComponent,
    CampoVeiculoComponent,
    CampoCameraComponent,
    CampoTipoDispositivoComponent,
    CampoTimezoneComponent,
    CampoTipoUnidadeComponent,
  ],
  exports:  [
    CampoCondominioComponent,
    CampoBlocoComponent,
    CampoIntegradoraComponent,
    CampoModeloControladoraComponent,
    CampoControladoraComponent,
    CompoModeloLeitoraComponent,
    CampoTipoAtendimentoComponent,
    CampoRotaComponent,
    CampoDispositivoComponent,
    CampoPessoaFisicaComponent,
    CampoTerceiroComponent,
    CampoVeiculoComponent,
    CampoCameraComponent,
    CampoTipoDispositivoComponent,
    CampoTimezoneComponent,
    CampoTipoUnidadeComponent,
  ],
  imports: [
    CommonModule,
    NgSelectModule,
    ReactiveFormsModule,
    FormsModule,
  ]
})
export class CamposModule { }
