import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  constructor() { }

  httpOptions = {};

  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (httpRequest.params.get('ignora') === 'S') {
      httpRequest.params.delete('ignora')
      return next.handle(httpRequest);
    }

    const authToken = sessionStorage.getItem('ablejwt');

    if (authToken) {

      if (httpRequest.body instanceof FormData) {
        httpRequest = httpRequest.clone({
          setHeaders: {
            Authorization: `Bearer ${authToken}`
          }
        });
      } else {

        const contentType = httpRequest.headers.get('content-type');

        if (contentType === 'multipart/form-data') {
          httpRequest = httpRequest.clone({
            setHeaders: {
              Authorization: `Bearer ${authToken}`,
              'Content-Type': 'multipart/form-data;'
            }
          });
        } else {
          httpRequest = httpRequest.clone({
            setHeaders: {
              Authorization: `Bearer ${authToken}`,
              'Content-Type': 'application/json'
            }
          });
        }
      }
    } else {
      httpRequest = httpRequest.clone({
        setHeaders: {
          'Content-Type': 'application/json'
        }
      });
    }

    return next.handle(httpRequest);
  }
}
