import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { NgbNavModule } from "@ng-bootstrap/ng-bootstrap";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import {
  CheckboxModule,
  MDBBootstrapModulesPro,
  MdbTreeModule,
} from "ng-uikit-pro-standard";
import { CustomFormsModule } from "ngx-custom-validators";
import { NgxMaskModule } from "ngx-mask";
import { UiSwitchModule } from "ngx-ui-switch";
import { FocusFormModule } from "src/app/core/directives/focus-form.module";

import { DvrsManipulateComponent } from "./dvrs-manipulate/dvrs-manipulate.component";
import { DvrsTableComponent } from "./dvrs-table/dvrs-table.component";

@NgModule({
  declarations: [DvrsManipulateComponent, DvrsTableComponent],
  exports: [DvrsManipulateComponent, DvrsTableComponent],
  imports: [
    CommonModule,
    MatTableModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MatFormFieldModule,
    MatInputModule,
    NgbNavModule,
    MatDialogModule,
    MatButtonModule,
    CustomFormsModule,
    FormsModule,
    CustomFormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot({ validation: true }),
    MdbTreeModule,
    MDBBootstrapModulesPro.forRoot(),
    CheckboxModule,
    NgMultiSelectDropDownModule.forRoot(),
    FocusFormModule,
    UiSwitchModule,
  ],
})
export class DvrsModule {}
