import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';
import { HttpService } from 'src/app/core/services/http.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { MensagemService } from 'src/app/core/services/mensagem.service';

class ImagemDTO {
  id: number;
  nome: string;
  imagem: ImagemDadosDTO;
}

class ImagemDadosDTO {
  id: number;
  nome: string;
  tipo: string;
  dados: string;
}
@Component({
  selector: 'app-imagem-edit',
  templateUrl: './imagem-edit.component.html',
  styleUrls: ['./imagem-edit.component.scss']
})
export class ImagemEditComponent implements OnInit {


  imagem: string;
  id: any;
  titulo: any;
  endPointGet: string;
  endPointUpload: string;

  constructor(public activeModal: NgbActiveModal,
    private httpService: HttpService,
    private loadingService: LoadingService,
    private msg: MensagemService) { }

  ngOnInit(): void {
    this.carregaImagem();
  }


  adicionarFoto(){
    document.getElementById('input-file').click();
  }

  upload(event) {
    
    const file:File = <File>event.target.files[0];

    console.log(file);

    this.httpService.patchImage(this.endPointUpload, '?id=' + this.id, file)
    .pipe(
      finalize(() => {
        this.carregaImagem()
      })
    )
    .subscribe(it => {
        }, err => {
          console.log(err)
        })
 }

  carregaImagem() {
    const spinnerRef = this.loadingService.start('Carregando...');
    this.httpService.requestAPI(this.endPointGet, null, `id=${this.id}`)
    .pipe(
      finalize(() => {
        this.loadingService.stop(spinnerRef);
      })
    )
    .subscribe(data => {
      const responseImagem: ImagemDTO = data;

      if (responseImagem && responseImagem.imagem) {
        const { imagem } = responseImagem;
        this.imagem = `data:${imagem.tipo};base64,${imagem.dados}`;
      }
    });  }


}
