import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmComponent } from './../../views/components/confirm/confirm.component';
import { LoadingComponent } from './../../views/components/loading/loading.component';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LoadingPerifericoComponent } from 'src/app/views/components/loading-periferico/loading-periferico.component';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  constructor(private router: Router, private dialog: NgbModal) {
  }

  start(message?) {
    const mensagem = message == ''|| message == undefined ? 'Aguarde...' : message;
    const dialogRef = this.dialog.open(
      LoadingComponent,
      {centered: true, backdrop: 'static', keyboard : false, windowClass : 'testeBreno'}
      );
    dialogRef.componentInstance.dataText = mensagem;
    return dialogRef;
};

startPeriferico(modelo?, tipo?, camera?, action?, dados?, modal?) {
  if(action == 'cadastrarPerifericoSerial' || action == 'cadastrarFacial'){
    return this.start('Gravando...');
  }
  const mensagem = 'Aguarde...';
  const dialogRef = this.dialog.open(LoadingPerifericoComponent, {centered: true, backdrop: 'static', keyboard : false, windowClass : 'app-modal-window'});
  dialogRef.componentInstance.carrega(tipo, modelo, camera, action, dados, modal);
  return dialogRef;
};

stop(ref:any){
      ref.close();
  }

}
