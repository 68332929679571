import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
  MatSnackBarRef
} from '@angular/material/snack-bar';
import { MessageSnackbarComponent } from 'src/app/views/components/message-snackbar/message-snackbar.component';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class MensagemService {

  snackBarConfig: MatSnackBarConfig;
  snackBarRef: MatSnackBarRef<any>;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  snackBarAutoHide = '5000';
  channelArray: any = ['401', '403'];

  constructor(private snackBar: MatSnackBar, private router: Router) { }

  openSnackBar2(tipo: string, obj: any){
    if(obj.error.message){
      this.openSnackBar(tipo, obj.error.message, obj.error.status);
      return
    }

    this.openSnackBar(tipo, obj.error.map((x: any) => {
      if (x.campo) {
        return x.campo + ' ' + x.erro + '\n';
      }

      return x.erro + '\n';
    }), obj.error.status);
  }

  openSnackBar(tipo: string, mensagem: string, status: string) {
    this.snackBarConfig = new MatSnackBarConfig();
    this.snackBarConfig.horizontalPosition = this.horizontalPosition;
    this.snackBarConfig.verticalPosition = this.verticalPosition;
    this.snackBarConfig.duration = parseInt(this.snackBarAutoHide, 0);
    this.snackBarConfig.panelClass = 'glam-snackbar';
    if(tipo == 'erro'){
      this.snackBarConfig.panelClass = ['mat-warn'];
    }
    if (mensagem != null && mensagem.length != 0 && status == '403') {
      const dialog = Swal.fire({
        title: "Acesso negado, você esta sendo redirecionado!",
        icon:  'error',
        focusConfirm: true,
        confirmButtonText:'OK',
        allowOutsideClick: false,
      })
      dialog.then((result) => {
        if (result.isConfirmed) {
          this.router.navigate(['/auth/login']);
          localStorage.removeItem('isLoggedin');
          localStorage.removeItem('ablejwt');
          sessionStorage.removeItem('ablejwt');
        }
      })
      return
    }

    console.log(tipo + ' - ' + mensagem + ' - =' + status + '=');

    if((mensagem == null || mensagem.length == 0) && this.channelArray.some(x => x == status.toString())){

      switch (status.toString()) {
        case '401':{
          mensagem = '401 - Não autorizado';
          break;
        }
        case '403':{
          mensagem = '403 - Acesso negado';
          break;
        }
        default:{
          mensagem = status + ' - Erro Desconhecidoo';
          break;
    }
      }
    }

    const dialog = Swal.fire({
      title: mensagem,
      icon:  'error',
      focusConfirm: true,
      confirmButtonText:'OK',
      allowOutsideClick: false,
    })
    dialog.then((result) => {
    })

    //this.snackBarConfig.data = {message: mensagem, snackType: tipo };
    //this.snackBarRef = this.snackBar.openFromComponent(MessageSnackbarComponent, this.snackBarConfig);
}

}