import { Pipe, PipeTransform } from "@angular/core";

@Pipe({name: 'cep'})
export class CEPPipe implements PipeTransform {
    transform(value: string, zenkaku: boolean = true): string {
        if (!value){
            return value;  
        }
        if(value.length < 8){
          return '';
        }else{
          return  `${value.substr(0,5)}-${value.substr(5, 3)}`;
        }
      }
}