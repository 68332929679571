import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogModel } from 'src/app/core/models/DialogModel';

@Component({
  selector: 'app-mensagem-confirma',
  templateUrl: './mensagem-confirma.component.html',
  styleUrls: ['./mensagem-confirma.component.scss']
})
export class MensagemConfirmaComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public dialog:DialogModel) { }

  ngOnInit(): void {
  }

}
