import { HttpClient, HttpHeaders } from "@angular/common/http";
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { Observable, Subscription } from "rxjs";
import { CameraDvrDTO } from "src/app/core/models/CameraDvrDTO";
import { DvrDTO } from "src/app/core/models/DvrDTO";
import { HttpService } from "src/app/core/services/http.service";
import { LoadingService } from "src/app/core/services/loading.service";

@Component({
  selector: "app-camera-dvr",
  templateUrl: "./camera-dvr.component.html",
  styleUrls: ["./camera-dvr.component.scss"],
})
export class CameraDvrComponent implements OnInit, OnDestroy {
  @Output()
  camerasSelecionadasEvent: EventEmitter<CameraDvrDTO[]> = new EventEmitter();

  @Input()
  condominio: number;

  @Input()
  camerasSelecionadas: CameraDvrDTO[] = [];

  @Input()
  getDvrsCondominio: () => Promise<DvrDTO[]>;

  dvrs: DvrDTO[] = [];
  cameras: CameraDvrDTO[] = [];
  semImagem = "./assets/images/2x/outline_videocam_off_white_24dp.png";
  subs: Subscription[] = [];
  preencheuDVRS = false;
  carregouCameras = false;

  constructor(
    private httpClient: HttpClient,
    private httpService: HttpService,
    private loadingService: LoadingService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  async ngOnInit() {
    if (this.condominio > 0) {
      this.dvrs = await this.getDvrsCondominio();
      if (this.dvrs) {
        this.carregaCameras();
      }
    }
  }

  carregaCameras() {
    this.dvrs.forEach((dvr) => {
      this.subs.push(
        this.httpClient
          .get(`${dvr.endereco}:${dvr.porta}/camerasnomes.cgi`, {
            headers: {
              Authorization: `Basic ${btoa(`${dvr.usuario}:${dvr.senha}`)}`,
            },
            responseType: "blob",
            params: { ignora: "S" },
          })
          .subscribe((result: Blob) => {
            const reader = new FileReader();
            reader.onload = () => {
              const retornoString = `${reader.result}`;
              retornoString.split("&").forEach((cameraObj: string) => {
                let cameraAux = cameraObj.split("=");
                const camera: CameraDvrDTO = {
                  codigo: cameraAux[0],
                  descricao: cameraAux[1],
                  idDvr: dvr.id,
                  exibeAtendimento: this.camerasSelecionadas
                    .map(({ codigo }) => codigo)
                    .includes(cameraAux[0]),
                };
                this.cameras.push(camera);
                this.carregaImagensCameras(camera, dvr);
              });
            };
            if (result) {
              reader.readAsText(result);
            }
          })
      );
    });
  }

  private carregaImagensCameras(camera: CameraDvrDTO, dvr: DvrDTO) {
    this.subs.push(
      this.httpClient
        .get(
          `${dvr.endereco}:${dvr.porta}/camera.cgi?resolucao=320x240&amp;qualidade=50&amp;formato=jpg&amp;canrepeatimage=false;camera=${camera.codigo}`,
          {
            headers: {
              Authorization: `Basic ${btoa(`${dvr.usuario}:${dvr.senha}`)}`,
            },
            responseType: "blob",
            params: { ignora: "S" },
          }
        )
        .subscribe((result: any) => {
          camera.imagem = this.sanitizer.bypassSecurityTrustUrl(
            URL.createObjectURL(result)
          );
        })
    );
  }

  changeExibeAtendimento(check: boolean, camera: CameraDvrDTO) {
    if (check) {
      this.camerasSelecionadas.push(camera);
    } else {
      this.camerasSelecionadas = this.camerasSelecionadas.filter(
        (cameraFilter) =>
          `${cameraFilter.codigo}:${cameraFilter.idDvr}` !==
          `${camera.codigo}:${camera.idDvr}`
      );
    }
    this.camerasSelecionadasEvent.emit(this.camerasSelecionadas);
  }
}
