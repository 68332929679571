import { MessageSnackbarComponent } from './views/components/message-snackbar/message-snackbar.component';
import { MensagemConfirmaService } from 'src/app/core/services/mensagem-confirma.service';
import { MensagemService } from './core/services/mensagem.service';
import { LoadingService } from './core/services/loading.service';
import { AuthService } from './core/services/auth.service';
import { HttpService } from './core/services/http.service';
import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';

import { LayoutModule } from './views/layout/layout.module';
import { AuthGuard } from './core/guard/auth.guard';

import { AppComponent } from './app.component';
import { ErrorPageComponent } from './views/pages/error-page/error-page.component';

import { HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import { registerLocaleData } from '@angular/common';
import ptBr from '@angular/common/locales/pt';
import { HomeComponent } from './views/pages/home/home.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { InterceptorService } from './core/services/interceptor.service';
import { LoadingComponent } from './views/components/loading/loading.component';
import { ConfirmComponent } from './views/components/confirm/confirm.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSliderModule } from '@angular/material/slider';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { getDutchPaginatorIntl } from './core/util/dutch-paginator-intl';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { MensagemConfirmaComponent } from './views/components/mensagem-confirma/mensagem-confirma.component';
import { LoadingPerifericoComponent } from './views/components/loading-periferico/loading-periferico.component';
import { DateTimePickerComponent } from './views/components/date-time-picker/date-time-picker.component';
import { ImagemEditComponent } from './views/components/imagem-edit/imagem-edit.component';
import { UtilModule } from './core/util/util.module';
import { DvrsModule } from './views/able-pages/dvrs/dvrs.module';
import { CameraDvrModule } from './views/able-pages/camera-dvr/camera-dvr.module';

registerLocaleData(ptBr);

@NgModule({
  declarations: [
    AppComponent,
    ErrorPageComponent,
    HomeComponent,
    LoadingComponent,
    ConfirmComponent,
    MessageSnackbarComponent,
    MensagemConfirmaComponent,
    LoadingPerifericoComponent,
    DateTimePickerComponent,
    ImagemEditComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    HttpClientModule,
    MatDialogModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatSliderModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot({ validation: true}),
    NgSelectModule,
    UtilModule,
    NgMultiSelectDropDownModule.forRoot(),
    DvrsModule,
    CameraDvrModule,
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'pt-BR' } ,
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    { provide: MatPaginatorIntl, useValue: getDutchPaginatorIntl() },
    AuthGuard,
    AuthService,
    HttpService,
    LoadingService,
    MensagemService,
    MensagemConfirmaService,
    {
      provide: HIGHLIGHT_OPTIONS, // https://www.npmjs.com/package/ngx-highlightjs
      useValue: {
        coreLibraryLoader: () => import('highlight.js/lib/core'),
        languages: {
          xml: () => import('highlight.js/lib/languages/xml'),
          typescript: () => import('highlight.js/lib/languages/typescript'),
          scss: () => import('highlight.js/lib/languages/scss'),
        }
      }
    }
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    MensagemConfirmaComponent
  ]
})
export class AppModule { }
