import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Observable, Subject } from 'rxjs';
import { ContentDTO } from 'src/app/core/models/ContentDTO';
import { ModeloTimezoneDTO } from 'src/app/core/models/ModeloTimezoneDTO';
import { HttpService } from 'src/app/core/services/http.service';
import { MensagemService } from 'src/app/core/services/mensagem.service';

@Component({
  selector: 'campo-timezone',
  templateUrl: './campo-timezone.component.html',
  styleUrls: ['./campo-timezone.component.scss']
})
export class CampoTimezoneComponent implements OnInit {

  campo: string = "Fuso horário";
  controlname: string = "timezone";
  searchable: boolean = false;
  clearable: boolean = false;
  carregamentoInicial = true;

  @Input() formGroupName: string;
  @Input() endPointPsq: string;
  @Input() endPointFindById: string;
  @Output() campoEmit = new EventEmitter<any>();
  @ViewChild(NgSelectComponent) ngSelect: NgSelectComponent;
  itemsFixo : ModeloTimezoneDTO[];

  form: FormGroup;
  items$: Observable<any>;
  minimoCaracteresBusca = 2;
  pesquisando = false;
  searchInput$ = new Subject<string>();
  idSelecionado: string;
  idIni: string;
  idInicial: number;

  constructor(private httpService: HttpService,
    private rootFormGroup: FormGroupDirective,
    private msg: MensagemService) { }

  ngOnInit(): void {
    this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
    this.getData().subscribe(
      data => {
        setTimeout(() => {
          this.itemsFixo = data as ModeloTimezoneDTO[];
          if(this.itemsFixo.length > 0){
            if(this.idInicial == null) {
              this.idInicial = 199;
            }
            this.idSelecionado = this.itemsFixo[this.idInicial-1].id;
          }
        }, 1000)
      }
    )
  }

  trackByFn(item: ContentDTO) {
    return item.id;
  }

  getData(): Observable<any> {
    
    return this.httpService.requestAC(this.endPointPsq, null, "page=0&size=99999&sort=id,asc", null);
  }

  onChange() {
    this.campoEmit.emit({ id: this.idSelecionado });
  }

  clear() {
    this.ngSelect.clearModel();
  }

  setIdInicial(id: number) {
    this.idInicial = id;
  }
}
