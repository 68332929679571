import { AuthService } from './../services/auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot, CanActivateChild, UrlTree } from '@angular/router';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Roles } from '../enums/roles.enum';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
  
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (this.authService.isAuthenticated()) {
      // console.log(route.data + "--------------------------------------------");
      // console.log(route.data.allowdRoles + "--------------------------------------------");

      if(!this.authService.isAuthorized(route.data.allowedRoles)){
        this.router.navigate(['/acessonegado']);
        return false;
      }
      return true;
    }
    this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // console.log(route.data + "--------------------------------------------");
    // console.log(route.data.allowedRoles + "--------------------------------------------");

    if (this.authService.isAuthenticated()) {
      console.log("autorizado");     

      if(!this.authService.isAuthorized(route.data.allowedRoles)){
        this.router.navigate(['/acessonegado']);
        return false;
      }
      return true;
    }
    this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }
}