import { ContentDTO } from './../../../core/models/ContentDTO';
import { TrocarIntegradoraComponent } from './trocar-integradora/trocar-integradora.component';
import { DadosUsuarioComponent } from './dados-usuario/dados-usuario.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlterarSenhaComponent } from './alterar-senha/alterar-senha.component';
import { MensagemService } from './../../../core/services/mensagem.service';
import { finalize } from 'rxjs/operators';
import { HttpService } from './../../../core/services/http.service';
import { LoadingService } from './../../../core/services/loading.service';
import { Component, OnInit, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { MensagemConfirmaService } from 'src/app/core/services/mensagem-confirma.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  permiteTrocarIntegradora: boolean;

  constructor(
    @Inject(DOCUMENT) private document: Document, 
    private renderer: Renderer2,
    private router: Router,
    private httpSerice: HttpService,
    private loadingService: LoadingService,
    private msg: MensagemService,
    private msgConfirma: MensagemConfirmaService,
    private modalService: NgbModal
    ) { }
  apelido: string;
  integradora: string;

  ngOnInit(): void {
    this.apelido = sessionStorage.getItem('apelido');

    if(this.apelido === null || this.apelido === '') {
      this.apelido = sessionStorage.getItem('nome');
    }

    this.integradora = sessionStorage.getItem('integradora');
    this.permiteTrocarIntegradora = JSON.parse(sessionStorage.getItem('integradoras')).length > 1;
  }

  /**
   * Sidebar toggle on hamburger button click
   */
  toggleSidebar(e) {
    e.preventDefault();
    this.document.body.classList.toggle('sidebar-open');
  }

  /**
   * Logout
   */
  onLogout(e) {
    e.preventDefault();

    this.msgConfirma.openConfirmDialog("Deseja realmente sair ?", (result: any) => {
      if (result.isConfirmed) {
        localStorage.removeItem('isLoggedin');
        var spinnerRef = this.loadingService.start();
        this.httpSerice.requestAPI('logout')
       .pipe(finalize(() => {  
        this.loadingService.stop(spinnerRef);
      })).subscribe(
      data => {
        localStorage.removeItem('ablejwt');
        sessionStorage.removeItem('ablejwt');
        this.router.navigate(['/auth/login']);
      },
      err => {
        this.msg.openSnackBar('erro', err['message'], err['status']);
      }  
    );    
    }
    },"warning", "Sair", "Sair", "Cancelar"
    )

  }

  alterarSenha(e) {
    e.preventDefault();
    this.modalService.open(AlterarSenhaComponent, {size: 'md', backdrop: 'static', keyboard : false});
  }

  alterarIntegradora(e){
    e.preventDefault();
    this.modalService.open(TrocarIntegradoraComponent, {size: 'md', backdrop: 'static', keyboard : false});
  }

  dadosUsuario(e, tipo) {
    e.preventDefault();
    let modal;
    modal = this.modalService.open(DadosUsuarioComponent,{size: 'lg', backdrop: 'static', keyboard : false});
    modal.componentInstance.tipo = tipo;
    modal.result.then((data) => {
      if (data) {
        this.apelido = data;
      }
    }, (reason) => {
      // on dismiss
    });  
  }

  


}
