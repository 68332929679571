import { SweetAlertIcon } from 'sweetalert2';
import { MatDialog } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { DialogModel } from '../models/DialogModel';
import { MensagemConfirmaComponent } from 'src/app/views/components/mensagem-confirma/mensagem-confirma.component';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class MensagemConfirmaService {

  constructor(public dialog: MatDialog) { }

  private openDialog(dialogData: DialogModel) {
     
    const dialogRef = Swal.fire({
      title: dialogData.title,
      icon:  dialogData.type,
      html: dialogData.message,
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText:dialogData.botaoConfirmar,
      cancelButtonText:dialogData.botaoCancelar,
    })

    return dialogRef;
  }
  openConfirmDialog(message: string,  callBackFunction: Function, type: SweetAlertIcon, title: string, botaoConfirma ?:string, botaoCancelar ?:string): void {
    const dialogRef = this.openDialog(new DialogModel(message, title, type, botaoConfirma, botaoCancelar ))
    dialogRef.then((result) => {
      callBackFunction(result)
    })
  }

  private openDialogInfo(dialogData: DialogModel) {
     
    const dialogRef = Swal.fire({
      title: dialogData.title,
      icon:  dialogData.type,
      html: dialogData.message,
      showCloseButton: true,
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonText:dialogData.botaoConfirmar,
      cancelButtonText:dialogData.botaoCancelar,
    })

    return dialogRef;
  }
  openInfoDialog(message: string,  callBackFunction: Function, type: SweetAlertIcon, title: string, botaoConfirma ?:string): void {
    const dialogRef = this.openDialogInfo(new DialogModel(message, title, type, botaoConfirma, null ))
    dialogRef.then((result) => {
      callBackFunction(result)
    })
  }
}
