import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, startWith, switchMap, tap } from 'rxjs/operators';
import { ContentDTO } from 'src/app/core/models/ContentDTO';
import { HttpService } from 'src/app/core/services/http.service';
import { MensagemService } from 'src/app/core/services/mensagem.service';

export interface IntegradoraDTO {
  id: string;
  codigo: string;
  nome: string;
  ativo: string;
}
@Component({
  selector: 'campo-integradora',
  templateUrl: './campo-integradora.component.html',
  styleUrls: ['./campo-integradora.component.scss']
})
export class CampoIntegradoraComponent implements OnInit {

  campo: string = "integradora";
  controlname: string = "integradora";
  searchable: boolean = true;
  clearable: boolean = false;
  carregamentoInicial = true;

  @Input() formGroupName: string;
  @Input() endPointPsq: string;
  @Input() endPointFindById: string;
  @Output() campoEmit = new EventEmitter<any>();
  @ViewChild(NgSelectComponent) ngSelect: NgSelectComponent;
  @Input() intergradoraUsuario: boolean = false;
  itemsFixo: ContentDTO[];
  @Input() selecionaPrimeiro: boolean = true;

  form: FormGroup;
  items$: Observable<any>;
  minimoCaracteresBusca = 2;
  pesquisando = false;
  searchInput$ = new Subject<string>();
  idSelecionado: string;
  idIni: number = 0;

  constructor(private httpService: HttpService,
    private rootFormGroup: FormGroupDirective,
    private msg: MensagemService,) { }

  ngOnInit(): void {
    this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
    this.itemsFixo = JSON.parse(sessionStorage.getItem('integradoras'));
    if (!this.intergradoraUsuario) {
      this.items$ =
        this.searchInput$.pipe(
          startWith(''),
          filter(res => {
            return this.carregamentoInicial || (res !== null && res.length >= this.minimoCaracteresBusca)
          }),
          distinctUntilChanged(),
          debounceTime(500),
          tap(() => this.pesquisando = true),
          switchMap(term => {
            this.carregamentoInicial = false;
            return this.getData(term).pipe(
              catchError(() => of([])), // empty list on error
              tap(data => {
                this.pesquisando = false;
                if (this.selecionaPrimeiro || this.idIni > 0) {
                  this.onChange();
                  this.idSelecionado = data[0].id;
                  if (this.idIni > 0) {
                    let idSel = data.find(x => x.id == this.idIni);
                    if (idSel) {
                      this.idSelecionado = idSel.id;
                    }
                    this.idIni = 0;
                  }
                }
              })
            )
          })
        );
    } else {
      this.carregamentoInicial = false;
    }
  }

  trackByFn(item: ContentDTO) {
    return item.id;
  }

  getData(searchTerm: string): Observable<any> {
    let params = "permiteInativos=true&page=0&size=50&sort=nome,asc&sort=id,asc";
    return this.httpService.requestAC(this.endPointPsq, null, params, searchTerm == null ? '' : searchTerm);
  }

  onChange() {
    this.campoEmit.emit({ id: this.idSelecionado });
  }

  clear() {
    this.ngSelect.clearModel();
  }

  setSelectedId(id: number) {
    if(id === 0 || id === null){
      return;
    }
    if (this.carregamentoInicial && this.ngSelect.items == null) {
      this.idIni = id;
      return;
    }
    let idSel = this.ngSelect.items.find(x => x.id == id);
    if (idSel) {
      this.idSelecionado = idSel.id;
      this.onChange();
    } else {
      this.httpService.findById(this.endPointFindById, id, true)
        .pipe().subscribe(
          data => {
            const millis: number = data.dataExpiracao;
            let integradora = data as IntegradoraDTO;
            this.items$ = of([{ id: integradora.id, codigo: integradora.codigo, nome: integradora.nome, ativo: integradora.ativo }]);
            if (this.ngSelect.items.length > 0) {
              this.idSelecionado = this.ngSelect.items[0].id;
              this.onChange();
            }
          },
          err => {
            this.msg.openSnackBar("erro", err['message'], err['status']);
          }
        );
    }
  }


}
