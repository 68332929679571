export enum Roles {
  // Acesso master
  MASTER = 'MASTER',

  // Configuração
  CONFIGURACAO = 'CONFIGURACAO',
  CONFIGURACAO_R = 'CONFIGURACAO_R',
  CONFIGURACAO_W = 'CONFIGURACAO_W',

  // Grupo
  GRUPO = 'GRUPO',
  GRUPO_R = 'GRUPO_R',
  GRUPO_W = 'GRUPO_W',
  GRUPO_I = 'GRUPO_I',

  // Usuario
  USUARIO = 'USUARIO',
  USUARIO_R = 'USUARIO_R',
  USUARIO_W = 'USUARIO_W',
  USUARIO_I = 'USUARIO_I',

  // Integradora
  INTEGRADORA = 'INTEGRADORA',
  INTEGRADORA_R = 'INTEGRADORA_R',
  INTEGRADORA_W = 'INTEGRADORA_W',
  INTEGRADORA_I = 'INTEGRADORA_I',

  // Condomínio
  CONDOMINIO = 'CONDOMINIO',
  CONDOMINIO_R = 'CONDOMINIO_R',
  CONDOMINIO_W = 'CONDOMINIO_W',
  CONDOMINIO_I = 'CONDOMINIO_I',

  // Unidade
  UNIDADE = 'UNIDADE',
  UNIDADE_R = 'UNIDADE_R',
  UNIDADE_W = 'UNIDADE_W',
  UNIDADE_I = 'UNIDADE_I',

  // Unidade tipo
  UNIDADE_TIPO = 'UNIDADE_TIPO',
  UNIDADE_TIPO_R = 'UNIDADE_TIPO_R',
  UNIDADE_TIPO_W = 'UNIDADE_TIPO_W',
  UNIDADE_TIPO_I = 'UNIDADE_TIPO_I',

  // IP
  IP = 'IP',
  IP_R = 'IP_R',
  IP_W = 'IP_W',
  IP_I = 'IP_I',

  // Bloco
  BLOCO = 'BLOCO',
  BLOCO_R = 'BLOCO_R',
  BLOCO_W = 'BLOCO_W',
  BLOCO_I = 'BLOCO_I',

  // Pessoa física
  PESSOA_FISICA = 'PESSOA_FISICA',
  PESSOA_FISICA_R = 'PESSOA_FISICA_R',
  PESSOA_FISICA_W = 'PESSOA_FISICA_W',
  PESSOA_FISICA_I = 'PESSOA_FISICA_I',

  // Terceiro
  TERCEIRO = 'TERCEIRO',
  TERCEIRO_R = 'TERCEIRO_R',
  TERCEIRO_W = 'TERCEIRO_W',
  TERCEIRO_I = 'TERCEIRO_I',

  // Veiculo
  VEICULO = "VEICULO",
  VEICULO_R = "VEICULO_R",
  VEICULO_W = "VEICULO_W",
  VEICULO_I = "VEICULO_I",

  // Unidade restrição
	UNIDADE_RESTRICAO = "UNIDADE_RESTRICAO",
	UNIDADE_RESTRICAO_R = "UNIDADE_RESTRICAO_R",
	UNIDADE_RESTRICAO_W = "UNIDADE_RESTRICAO_W",
	UNIDADE_RESTRICAO_I = "UNIDADE_RESTRICAO_I",

  // Diretriz
	DIRETRIZ   = "DIRETRIZ",
	DIRETRIZ_R = "DIRETRIZ_R",
	DIRETRIZ_W = "DIRETRIZ_W",
	DIRETRIZ_I = "DIRETRIZ_I",

  // DVR
	DVR   = "DVR",
	DVR_R = "DVR_R",
	DVR_W = "DVR_W",
	DVR_I = "DVR_I",

  // Placa
  PLACA = "PLACA",
  PLACA_R = "PLACA_R",
  PLACA_W = "PLACA_W",
  PLACA_I = "PLACA_I",

  // Rota
	ROTA = 'ROTA',
	ROTA_R = 'ROTA_R',
	ROTA_W = 'ROTA_W',
	ROTA_I = 'ROTA_I',

  // Camera
  CAMERA = "CAMERA",
  CAMERA_R = "CAMERA_R",
  CAMERA_W = "CAMERA_W",
  CAMERA_I = "CAMERA_I",

  // Pet tipo
  PET   = "PET",
	PET_R = "PET_R",
	PET_W = "PET_W",
	PET_I = "PET_I",
	
	// Pet tipo
	PET_TIPO   = "PET_TIPO",
	PET_TIPO_R = "PET_TIPO_R",
	PET_TIPO_W = "PET_TIPO_W",
	PET_TIPO_I = "PET_TIPO_I",

  // Dispositivo
  DISPOSITIVO = "DISPOSITIVO",
  DISPOSITIVO_R = "DISPOSITIVO_R",
  DISPOSITIVO_W = "DISPOSITIVO_W",
  DISPOSITIVO_I = "DISPOSITIVO_I",
  // Acionamento
  DISPOSITIVO_AC = "DISPOSITIVO_AC",
  // Reinicialização
  DISPOSITIVO_RN = "DISPOSITIVO_RN",

  // Tipo atendimento
  ATENDIMENTO_TIPO   = "ATENDIMENTO_TIPO",
  ATENDIMENTO_TIPO_R = "ATENDIMENTO_TIPO_R",
  ATENDIMENTO_TIPO_W = "ATENDIMENTO_TIPO_W",
  ATENDIMENTO_TIPO_I = "ATENDIMENTO_TIPO_I",

  // Atendimento
  ATENDIMENTO     = "ATENDIMENTO",
  // Atender 
  ATENDIMENTO_A   = "ATENDIMENTO_A",
  // Reiniciar dispositivo
  ATENDIMENTO_D_R = "ATENDIMENTO_D_R",
  // Acionar dispositivo
  ATENDIMENTO_D_A = "ATENDIMENTO_D_A",

  // Perifericos
	PERIFERICO = 'PERIFERICO',
	PERIFERICO_R = 'PERIFERICO_R',
	PERIFERICO_W = 'PERIFERICO_W',
	PERIFERICO_I = 'PERIFERICO_I',

  // Relatorio
  RELATORIO = "RELATORIO",
  RELATORIO_R = "RELATORIO_R",
  RELATORIO_W = "RELATORIO_W",
  RELATORIO_I = "RELATORIO_I",
}
