import { Injectable } from '@angular/core';
import { ColorPickerComponent } from 'ngx-color-picker';
import { Observable, Subject, ReplaySubject, of } from 'rxjs';
import { delay, map, tap } from 'rxjs/operators';
import { HttpService } from './http.service';
import { HttpSearchModel } from './models/http-search-model';

@Injectable({
  providedIn: 'root'
})
export class HttpSearchService {  

  constructor(private httpService: HttpService,) { }

  private results: HttpSearchModel[];
  private filteredApps$: Subject<HttpSearchModel[]> = new ReplaySubject<HttpSearchModel[]>(1);

  getSearchResults(): Observable<HttpSearchModel[]> {
    return this.filteredApps$.asObservable();
  }

  search(searchTerm: string): Observable<void> {
    return this.fetchResults(searchTerm).pipe(
      tap((results: HttpSearchModel[]) => {
        results = results.filter(result => result.endereco.toLowerCase().includes(searchTerm));
        this.filteredApps$.next(results);
      }),
      map(() => void 0)
    );
  }

  private fetchResults(searchTerm): Observable<HttpSearchModel[]> {
    console.log(searchTerm);

    const res: HttpSearchModel[] = [];

    if(searchTerm && searchTerm != '') {      
      this.httpService.requestAPI("psqip", null, null, searchTerm)
        .subscribe(result => {
          result['content'].map(a => res.push(a));
        });
    }  

    return of(res).pipe(
      delay(500),
      tap((res: HttpSearchModel[]) => this.results = res)
    );
  }
}