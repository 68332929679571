import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'campo-tipo-dispositivo',
  templateUrl: './campo-tipo-dispositivo.component.html',
  styleUrls: ['./campo-tipo-dispositivo.component.scss']
})
export class CampoTipoDispositivoComponent implements OnInit {
  
  @Input() formGroupName: string;
  controlname: string = "tipoDispositivo";
  selectedTipo: string;
  form: FormGroup;


    tipos = [
        //{ constante: "ANTENA_UHF",      descricao: 'Antena UHF' },
        { constante: "BIOMETRIA",       descricao: 'Biometria' },
        { constante: "CARTAO",          descricao: 'Cartão' },
        { constante: "CONTROLE_REMOTO", descricao: 'Controle remoto' },
        { constante: "FACIAL",          descricao: 'Facial' },
        { constante: "TAG_PASSIVA",     descricao: 'Tag' },
    ];
  constructor(private rootFormGroup: FormGroupDirective) {  }
  
  ngOnInit(): void {
    this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
  }

  setSelectdTipo(tipo){
    this.selectedTipo = tipo;
  }

}
