import { MensagemService } from './../../../core/services/mensagem.service';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CameraDTO } from 'src/app/core/models/CameraDTO';
import { HttpService } from 'src/app/core/services/http.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-loading-periferico',
  templateUrl: './loading-periferico.component.html',
  styleUrls: ['./loading-periferico.component.scss']
})
export class LoadingPerifericoComponent implements OnInit {

  tipo:string;
  modelo:string;
  camera:number;
  action:string;
  dados:any;

  isCapturar: boolean = false;
  isCamera: boolean = false;

  constructor(public activeModal: NgbActiveModal,
    private httpService: HttpService,
    private msg: MensagemService) { }

  ngOnInit(): void {
  }

  carrega(tipo, modelo, camera, action, dados){
    this.tipo = tipo;
    this.modelo = modelo;
    this.camera = camera;
    this.action = action;
    this.dados = dados;
    this.isCapturar = true;
    
  }

  cancelar(){
    this.activeModal.close(true);
  }

  enviar(){
    this.isCapturar = false;
    this.httpService.requestAPI(this.action, this.dados)
    .pipe(
      finalize(() => {
        this.activeModal.close(false);
      })).subscribe(
        data => {
        },
        err => {
          this.msg.openSnackBar('erro', err['error'].message, err['status']);
        }
      );
  }
}
