import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Observable, Subject } from 'rxjs';
import { ContentDTO } from 'src/app/core/models/ContentDTO';
import { HttpService } from 'src/app/core/services/http.service';
import { MensagemService } from 'src/app/core/services/mensagem.service';

@Component({
  selector: 'campo-tipo-atendimento',
  templateUrl: './campo-tipo-atendimento.component.html',
  styleUrls: ['./campo-tipo-atendimento.component.scss']
})
export class CampoTipoAtendimentoComponent implements OnInit {

  campo: string = 'tipo de atendimento';
  controlname: string = 'tipoAtendimento';
  searchable: boolean = true;
  clearable: boolean = false;
  @Input() carregamentoInicial: boolean = false;

  @Input() formGroupName: string;
  @Input() endPointPsq: string;
  @Input() endPointFindById: string;
  @Input() extraParams: string;
  @Output() campoEmit = new EventEmitter<any>();
  @ViewChild(NgSelectComponent) ngSelect: NgSelectComponent;
  itemsFixo : ContentDTO[];

  form: FormGroup;
  items$: Observable<any>;
  minimoCaracteresBusca = 2;
  pesquisando = false;
  searchInput$ = new Subject<string>();
  idSelecionado: string;
  idIni: string;

  constructor(private httpService: HttpService,
    private rootFormGroup: FormGroupDirective,
    private msg: MensagemService,) { }

  ngOnInit(): void {
    this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
    this.getData().subscribe(
      data => {
        this.itemsFixo = data as ContentDTO[];
        if(this.carregamentoInicial && this.itemsFixo.length > 0){
          this.idSelecionado = this.itemsFixo[0].id;
        }
      }
    )
  }

  trackByFn(item: ContentDTO) {
    return item.id;
  }

  getData(): Observable<any> {
    return this.httpService.requestAC(this.endPointPsq, null, this.extraParams || '', null);
  }

  onChange() {
    this.campoEmit.emit({ id: this.idSelecionado });
  }

  clear() {
    this.ngSelect.clearModel();
  }

  setSelectedId(constante: string) {
    let idSel = this.ngSelect.items.find(x => x.constante == constante);
    if (idSel) {
      this.idSelecionado = idSel.constante;
      this.onChange();
    }
  }

}

