import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Roles } from '../enums/roles.enum';

const helper = new JwtHelperService ();

const jwtname: string = 'ablejwt';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor() {}

  public isAuthenticated(): boolean {
    if(localStorage.getItem(jwtname) != null && localStorage.getItem(jwtname) != undefined ){
      sessionStorage[jwtname] = localStorage.getItem(jwtname);
    }
    // let expDate = helper.getTokenExpirationDate(sessionStorage.getItem(jwtname));
    return !helper.isTokenExpired(sessionStorage.getItem(jwtname));
  }

  public isAuthorized(allowedRoles: string[]): boolean {
    if (allowedRoles == null || allowedRoles.length === 0) {
      return true;
    }

    const token = sessionStorage.getItem(jwtname);
    const decodeToken = helper.decodeToken(token);

    if (!decodeToken) {
      return false;
    }

    const rolesUser : string  = decodeToken['roles'];

    if(rolesUser == null) {
      return false;
    }
    return this.temRole(allowedRoles, rolesUser.toString().split(','));
  }

  private temRole(rolesNecessarias : string[], rolesUser: string[]) : boolean  {
    for(var i = 0; rolesUser.length > i; i++) {
      if(rolesUser[i] == Roles.MASTER) {
        return true;
      }
    }
    for(var i = 0; rolesNecessarias.length > i; i++) {
      for(var x = 0; rolesUser.length > x; x++) {
        if(rolesUser[x] == rolesNecessarias[i]) {
          return true;
        }
      }
    }
    return false;
  }
  
  public temRoleDiferente(regra : string) : boolean  {

    const roles : string  = helper.decodeToken(sessionStorage.getItem(jwtname))['roles'];
    
    const rolesUser = roles.toString().split(',')

    if(rolesUser == null) {
      return true;
    }

    for(var i = 0; rolesUser.length > i; i++) {
      if(rolesUser[i] == Roles.MASTER) {
        return true;
      }
    }

    if(rolesUser.length > 5){
      return true;
    }

    return rolesUser.some( r => !r.startsWith(regra));

  }
  
  public decode(){
    return helper.decodeToken(sessionStorage.getItem(jwtname));
  }

  public getUserId(): string{
    return helper.decodeToken(sessionStorage.getItem(jwtname))['sub'];
  }

  public getUserRoles(): string[]{
    return helper.decodeToken(sessionStorage.getItem(jwtname))['roles'];
  }

}
