import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';

import config from '../../../../src/config-able.json';
import { IdDTOIn } from '../models/IdDTOIn';
import { map, startWith } from 'rxjs/operators';

const PATH: string = config.protocolo + config.baseURL + ':' + config.porta  + '/able-api/';

interface api {
  function: string,
  type: string,
  endPoint: string,
  paramDefault: string
}

const API: api[] = [
  // LOGIN
  {function:'login', type:'post', endPoint:'auth/login', paramDefault:''},
  {function:'logout', type:'post', endPoint:'auth/logout', paramDefault:''},

  // PERMISSÕES
  {function:'permissoes', type:'get', endPoint:'permissao', paramDefault:'sort=descricao,asc'},
  {function:'findByIdpermissoes', type:'get', endPoint:'permissao', paramDefault:'sort=descricao,asc'},
  {function:'searchPermissoes', type:'get', endPoint:'permissao', paramDefault:'sort=descricao,asc'},

  // GRUPO
  {function:'buscarGrupo', type:'get', endPoint:'grupo', paramDefault:'permiteInativos=true'},
  {function:'cadastrarGrupo', type:'post', endPoint:'grupo', paramDefault:''},
  {function:'atualizarGrupo', type:'put', endPoint:'grupo', paramDefault:''},
  {function:'inativarGrupo', type:'patch', endPoint:'grupo/inativar', paramDefault:''},
  {function:'psqgrupo', type:'get', endPoint:'grupo', paramDefault:'permiteInativos=false&page=0&size=20&sort=id,asc&sort=nome,asc&sort=descricao,asc'},
  {function:'ativarGrupo', type:'patch', endPoint:'grupo/ativar', paramDefault:''},
  {function:'excluirGrupo', type:'delete', endPoint:'grupo', paramDefault:''},
  {function:'findByIdGrupo', type:'get', endPoint:'grupo', paramDefault:''},
  {function:'searchGrupo', type:'get', endPoint:'grupo/pesquisar', paramDefault:''},
  {function:'findByIdIntegradoraGrupo', type:'get', endPoint:'grupo/integradora', paramDefault:''},
  {function:'psqIntegradoraGrupo', type:'get', endPoint:'grupo/integradora', paramDefault:'permiteInativos=false&page=0&size=20&sort=id,asc&sort=nome,asc&sort=descricao,asc'},

  // USUARIO
  {function:'buscarUsuario', type:'get', endPoint:'usuario', paramDefault:'permiteInativos=true'},
  {function:'psqusuario', type:'get', endPoint:'usuario', paramDefault:'permiteInativos=false&page=0&size=20&sort=id,asc&sort=endereco,asc&sort=descricao,asc'},
  {function:'cadastrarUsuario', type:'post', endPoint:'usuario', paramDefault:''},
  {function:'atualizarUsuario', type:'put', endPoint:'usuario', paramDefault:''},
  {function:'atualizarDadosProprios', type:'put', endPoint:'usuario/atualizarDadosProprios', paramDefault:''},  
  {function:'alterarSenha', type:'patch', endPoint:'usuario/alterarSenha', paramDefault:''},
  {function:'geraNovaSenha', type:'patch', endPoint:'usuario/geraNovaSenha', paramDefault:''},
  {function:'inativarUsuario', type:'patch', endPoint:'usuario/inativar', paramDefault:''},
  {function:'ativarUsuario', type:'patch', endPoint:'usuario/ativar', paramDefault:''},
  {function:'excluirUsuario', type:'delete', endPoint:'usuario', paramDefault:''},
  {function:'findByIdUsuario', type:'get', endPoint:'usuario', paramDefault:''},
  {function:'searchUsuario', type:'get', endPoint:'usuario/pesquisar', paramDefault:''},
  {function:'getAvatar', type:'get', endPoint:'usuario/getAvatar', paramDefault:''},
  {function:'setAvatar', type:'get', endPoint:'usuario/uploadAvatar', paramDefault:''},
  {function:'alterarIntegradora', type:'patch', endPoint:'usuario/alterarIntegradoraDefault', paramDefault:''},
  {function:'atualizarDadosProprios', type:'patch', endPoint:'usuario/atualizarDadosProprios', paramDefault:''},
  {function:'psqgrupoUsuario', type:'get', endPoint:'usuario/grupo', paramDefault:'permiteInativos=false&page=0&size=20&sort=id,asc&sort=nome,asc&sort=descricao,asc'},
  {function:'findByIdGrupoUsuario', type:'get', endPoint:'usuario/grupo', paramDefault:''},

  // IP
  {function:'buscarIp', type:'get', endPoint:'ip', paramDefault:'permiteInativos=true'},
  {function:'psqip', type:'get', endPoint:'ip', paramDefault:'permiteInativos=false&page=0&size=20&sort=id,asc&sort=endereco,asc&sort=descricao,asc'},
  {function:'cadastrarIp', type:'post', endPoint:'ip', paramDefault:''},
  {function:'atualizarIp', type:'put', endPoint:'ip', paramDefault:''},
  {function:'inativarIp', type:'patch', endPoint:'ip/inativar', paramDefault:''},
  {function:'ativarIp', type:'patch', endPoint:'ip/ativar', paramDefault:''},
  {function:'excluirIp', type:'delete', endPoint:'ip', paramDefault:''},
  {function:'findIpById', type:'get', endPoint:'ip', paramDefault:''},
  {function:'findAllIp', type:'get', endPoint:'ip', paramDefault:''},
  {function:'searchIp', type:'get', endPoint:'ip/pesquisar', paramDefault:''},

  // INTEGRADORA
  {function:'buscarIntegradora', type:'get', endPoint:'integradora', paramDefault:'permiteInativos=true'},
  {function:'cadastrarIntegradora', type:'post', endPoint:'integradora', paramDefault:''},
  {function:'atualizarIntegradora', type:'put', endPoint:'integradora', paramDefault:''},
  {function:'inativarIntegradora', type:'patch', endPoint:'integradora/inativar', paramDefault:''},
  {function:'psqIntegradora', type:'get', endPoint:'integradora', paramDefault:'permiteInativos=false&page=0&size=20&sort=id,asc&sort=nome,asc&sort=descricao,asc'},
  {function:'ativarIntegradora', type:'patch', endPoint:'integradora/ativar', paramDefault:''},
  {function:'excluirIntegradora', type:'delete', endPoint:'integradora', paramDefault:''},
  {function:'findByIdIntegradora', type:'get', endPoint:'integradora', paramDefault:''},
  {function:'getLogoIntegradora', type:'get', endPoint:'integradora/getLogo', paramDefault:''},
  {function:'getAssinaturaIntegradora', type:'get', endPoint:'integradora/getAssinatura', paramDefault:''},
  {function:'findByCodigoIntegradora', type:'get', endPoint:'integradora/findByCodigo', paramDefault:''},
  {function:'setLogo', type:'patch', endPoint:'integradora/uploadLogo', paramDefault:''},
  {function:'setAssinatura', type:'patch', endPoint:'integradora/uploadAssinatura', paramDefault:''},
  {function:'searchIntegradora', type:'get', endPoint:'integradora/pesquisar', paramDefault:''},

  // CONDOMINIO
  {function:'psqCondominio', type:'get', endPoint:'condominio', paramDefault:'permiteInativos=false&page=0&size=20&sort=id,asc&sort=nome,asc&sort=descricao,asc'},
  {function:'buscarCondominio', type:'get', endPoint:'condominio', paramDefault:'permiteInativos=true'},
  {function:'excluirCondominio', type:'delete', endPoint:'condominio', paramDefault:''},
  {function:'searchCondominio', type:'get', endPoint:'condominio/pesquisar', paramDefault:''},
  {function:'findByCodigoCondominio', type:'get', endPoint:'condominio/findByCodigo', paramDefault:''},
  {function:'ativarCondominio', type:'patch', endPoint:'condominio/ativar', paramDefault:''},
  {function:'inativarCondominio', type:'patch', endPoint:'condominio/inativar', paramDefault:''},
  {function:'cadastrarCondominio', type:'post', endPoint:'condominio', paramDefault:''},
  {function:'atualizarCondominio', type:'put', endPoint:'condominio', paramDefault:''},
  {function:'findCondominioById', type:'get', endPoint:'condominio', paramDefault:''},
  {function:'findAllCondominioBloco', type:'get', endPoint:'condominio/bloco', paramDefault:''},
  {function:'searchCondominioBloco', type:'get', endPoint:'condominio/bloco/pesquisar', paramDefault:''},
  {function:'excluirCondominioBloco', type:'delete', endPoint:'condominio/bloco', paramDefault:''},
  {function:'cadastrarCondominioBloco', type:'post', endPoint:'condominio/bloco', paramDefault:''},
  {function:'atualizarCondominioBloco', type:'put', endPoint:'condominio/bloco', paramDefault:''},
  {function:'inativarCondominioBloco', type:'patch', endPoint:'condominio/bloco/inativar', paramDefault:''},
  {function:'ativarCondominioBloco', type:'patch', endPoint:'condominio/bloco/ativar', paramDefault:''},
  {function:'findAllCondominioUnidade', type:'get', endPoint:'condominio/unidade', paramDefault:''},
  {function:'searchCondominioUnidade', type:'get', endPoint:'condominio/unidade/pesquisar', paramDefault:''},
  {function:'excluirCondominioUnidade', type:'delete', endPoint:'condominio/unidade', paramDefault:''},
  {function:'cadastrarCondominioUnidade', type:'post', endPoint:'condominio/unidade', paramDefault:''},
  {function:'atualizarCondominioUnidade', type:'put', endPoint:'condominio/unidade', paramDefault:''},
  {function:'inativarCondominioUnidade', type:'patch', endPoint:'condominio/unidade/inativar', paramDefault:''},
  {function:'ativarCondominioUnidade', type:'patch', endPoint:'condominio/unidade/ativar', paramDefault:''},
  {function:'findAllCondominioDiretriz', type:'get', endPoint:'condominio/diretriz', paramDefault:''},
  {function:'searchCondominioDiretriz', type:'get', endPoint:'condominio/diretriz/pesquisar', paramDefault:''},

  // PESSOA FISICA
  {function:'psqPessoaFisica', type:'get', endPoint:'pessoaFisica', paramDefault:'permiteInativos=false&page=0&size=20&sort=id,asc&sort=nome,asc&sort=descricao,asc'},
  {function:'buscarPessoaFisica', type:'get', endPoint:'pessoaFisica', paramDefault:'permiteInativos=true'},
  {function:'excluirPessoaFisica', type:'delete', endPoint:'pessoaFisica', paramDefault:''},
  {function:'searchPessoaFisica', type:'get', endPoint:'pessoaFisica/pesquisar', paramDefault:''},
  {function:'findByCodigoPessoaFisica', type:'get', endPoint:'pessoaFisica/findByCodigo', paramDefault:''},
  {function:'ativarPessoaFisica', type:'patch', endPoint:'pessoaFisica/ativar', paramDefault:''},
  {function:'inativarPessoaFisica', type:'patch', endPoint:'pessoaFisica/inativar', paramDefault:''},
  {function:'cadastrarPessoaFisica', type:'post', endPoint:'pessoaFisica', paramDefault:''},
  {function:'atualizarPessoaFisica', type:'put', endPoint:'pessoaFisica', paramDefault:''},
  {function:'findPessoaFisicaById', type:'get', endPoint:'pessoaFisica', paramDefault:''},
  {function:'findAllPessoaFisicaCondominio', type:'get', endPoint:'pessoaFisica/condominio', paramDefault:''},
  {function:'searchPessoaFisicaCondominio', type:'get', endPoint:'pessoaFisica/condominio/pesquisar', paramDefault:''},
  {function:'findAllPessoaFisicaUnidade', type:'get', endPoint:'pessoaFisica/unidade', paramDefault:''},
  {function:'searchPessoaFisicaUnidade', type:'get', endPoint:'pessoaFisica/unidade/pesquisar', paramDefault:''},
  {function:'findAllPessoaFisicaVeiculo', type:'get', endPoint:'pessoaFisica/veiculo', paramDefault:''},
  {function:'searchPessoaFisicaVeiculo', type:'get', endPoint:'pessoaFisica/veiculo/pesquisar', paramDefault:''},
  {function:'findAllPessoaFisicaAtribuicoesCondominio', type:'get', endPoint:'pessoaFisica/listAllAtribuicoesCondominio', paramDefault:''},
  {function:'searchPessoaFisicaAtribuicoesCondominio', type:'get', endPoint:'pessoaFisica/pesquisarAtribuicaoCondominio', paramDefault:''},
  {function:'findAllPessoaFisicaAtribuicoesUnidade', type:'get', endPoint:'pessoaFisica/listAllAtribuicoesUnidade', paramDefault:''},
  {function:'searchPessoaFisicaAtribuicoesUnidade', type:'get', endPoint:'pessoaFisica/pesquisarAtribuicaoUnidade', paramDefault:''},
  {function:'psqPessoaFisicaCondominio', type:'get', endPoint:'pessoaFisica/condominio', paramDefault:'permiteInativos=false&page=0&size=20&sort=id,asc&sort=nome,asc'},
  {function:'findPessoaFisicaCondominioById', type:'get', endPoint:'pessoaFisica/condominio', paramDefault:''},
  {function:'atualizarVeiculosPessoaFisica', type:'post', endPoint:'pessoaFisica/atualizarVeiculos', paramDefault:''},
  {function:'pessoaImagemUpload', type:'patch', endPoint:'pessoaFisica/uploadImagem', paramDefault:''},
  {function:'pessoaImagemById', type:'get', endPoint:'pessoaFisica/getImagem', paramDefault:''},
  {function:'cadastrarBiometria', type:'put', endPoint:'pessoaFisica/periferico/cadastrarBiometria', paramDefault:''},
  {function:'cadastrarFacial', type:'put', endPoint:'pessoaFisica/periferico/uploadFacial', paramDefault:''},
  {function:'cadastrarPerifericoSerial', type:'put', endPoint:'pessoaFisica/periferico/cadastrarSerial', paramDefault:''},
  {function:'findAllPessoaFisicaPerifericoRota', type:'get', endPoint:'pessoaFisica/periferico/rota', paramDefault:''},
  {function:'searchPessoaFisicaPerifericoRotas', type:'get', endPoint:'pessoaFisica/periferico/rota/pesquisar', paramDefault:''},

  // TERCEIROS
  {function:'psqTerceiro', type:'get', endPoint:'terceiro', paramDefault:'permiteInativos=false&page=0&size=20&sort=id,asc&sort=nome,asc&sort=descricao,asc'},
  {function:'buscarTerceiro', type:'get', endPoint:'terceiro', paramDefault:'permiteInativos=true'},
  {function:'excluirTerceiro', type:'delete', endPoint:'terceiro', paramDefault:''},
  {function:'searchTerceiro', type:'get', endPoint:'terceiro/pesquisar', paramDefault:''},
  {function:'findByCodigoTerceiro', type:'get', endPoint:'terceiro/findByCodigo', paramDefault:''},
  {function:'ativarTerceiro', type:'patch', endPoint:'terceiro/ativar', paramDefault:''},
  {function:'inativarTerceiro', type:'patch', endPoint:'terceiro/inativar', paramDefault:''},
  {function:'cadastrarTerceiro', type:'post', endPoint:'terceiro', paramDefault:''},
  {function:'atualizarTerceiro', type:'put', endPoint:'terceiro', paramDefault:''},
  {function:'findTerceiroById', type:'get', endPoint:'terceiro', paramDefault:''},
  {function:'findAllTerceiroCondominio', type:'get', endPoint:'terceiro/condominio', paramDefault:''},
  {function:'searchTerceiroCondominio', type:'get', endPoint:'terceiro/condominio/pesquisar', paramDefault:''},
  {function:'findAllTerceiroUnidade', type:'get', endPoint:'terceiro/unidade', paramDefault:''},
  {function:'searchTerceiroUnidade', type:'get', endPoint:'terceiro/unidade/pesquisar', paramDefault:''},
  {function:'findAllTerceiroVeiculo', type:'get', endPoint:'terceiro/veiculo', paramDefault:''},
  {function:'searchTerceiroVeiculo', type:'get', endPoint:'terceiro/veiculo/pesquisar', paramDefault:''},
  {function:'findAllTerceiroAtribuicoesCondominio', type:'get', endPoint:'terceiro/listAllAtribuicoesCondominio', paramDefault:''},
  {function:'searchTerceiroAtribuicoesCondominio', type:'get', endPoint:'terceiro/pesquisarAtribuicaoCondominio', paramDefault:''},
  {function:'findAllTerceiroAtribuicoesUnidade', type:'get', endPoint:'terceiro/listAllAtribuicoesUnidade', paramDefault:''},
  {function:'searchTerceiroAtribuicoesUnidade', type:'get', endPoint:'terceiro/pesquisarAtribuicaoUnidade', paramDefault:''},
  {function:'psqTerceiroCondominio', type:'get', endPoint:'terceiro/condominio', paramDefault:'permiteInativos=false&page=0&size=20&sort=id,asc&sort=nome,asc'},
  {function:'findTerceiroCondominioById', type:'get', endPoint:'terceiro/condominio', paramDefault:''},
  {function:'terceiroImagemUpload', type:'patch', endPoint:'terceiro/uploadImagem', paramDefault:''},
  {function:'terceiroImagemDelete', type:'delete', endPoint:'terceiro/deletarImagem', paramDefault:''},
  {function:'TerceiroImagemById', type:'get', endPoint:'terceiro/getImagens', paramDefault:''},
  {function:'atualizarVeiculosTerceiro', type:'post', endPoint:'terceiro/atualizarVeiculos', paramDefault:''},

    // TIPOS UNIDADES
    {function:'buscarTiposUnidades', type:'get', endPoint:'unidadeTipo', paramDefault:'permiteInativos=true'},
    {function:'psqTiposUnidades', type:'get', endPoint:'unidadeTipo', paramDefault:'permiteInativos=false&page=0&size=20&sort=id,asc&sort=endereco,asc&sort=descricao,asc'},
    {function:'cadastrarTiposUnidades', type:'post', endPoint:'unidadeTipo', paramDefault:''},
    {function:'atualizarTiposUnidades', type:'put', endPoint:'unidadeTipo', paramDefault:''},
    {function:'inativarTiposUnidades', type:'patch', endPoint:'unidadeTipo/inativar', paramDefault:''},
    {function:'ativarTiposUnidades', type:'patch', endPoint:'unidadeTipo/ativar', paramDefault:''},
    {function:'excluirTiposUnidades', type:'delete', endPoint:'unidadeTipo', paramDefault:''},
    {function:'findTiposUnidadesById', type:'get', endPoint:'unidadeTipo', paramDefault:''},
    {function:'findAllTiposUnidades', type:'get', endPoint:'unidadeTipo', paramDefault:''},
    {function:'searchTiposUnidades', type:'get', endPoint:'unidadeTipo/pesquisar', paramDefault:''},

    // TIPOS PESSOA
    {function:'buscarTiposPessoa', type:'get', endPoint:'pessoaFisicaTipo', paramDefault:'permiteInativos=true'},
    {function:'psqTiposPessoa', type:'get', endPoint:'pessoaFisicaTipo', paramDefault:'permiteInativos=false&page=0&size=20&sort=id,asc&sort=endereco,asc&sort=descricao,asc'},
    {function:'cadastrarTiposPessoa', type:'post', endPoint:'pessoaFisicaTipo', paramDefault:''},
    {function:'atualizarTiposPessoa', type:'put', endPoint:'pessoaFisicaTipo', paramDefault:''},
    {function:'inativarTiposPessoa', type:'patch', endPoint:'pessoaFisicaTipo/inativar', paramDefault:''},
    {function:'ativarTiposPessoa', type:'patch', endPoint:'pessoaFisicaTipo/ativar', paramDefault:''},
    {function:'excluirTiposPessoa', type:'delete', endPoint:'pessoaFisicaTipo', paramDefault:''},
    {function:'findTiposPessoaById', type:'get', endPoint:'pessoaFisicaTipo', paramDefault:''},
    {function:'findAllTiposPessoa', type:'get', endPoint:'pessoaFisicaTipo', paramDefault:''},
    {function:'searchTiposPessoa', type:'get', endPoint:'pessoaFisicaTipo/pesquisar', paramDefault:''},

    // TIPOS TERCEIRO
    {function:'buscarTiposTerceiro', type:'get', endPoint:'terceiroTipo', paramDefault:'permiteInativos=true'},
    {function:'psqTiposTerceiro', type:'get', endPoint:'terceiroTipo', paramDefault:'permiteInativos=false&page=0&size=20&sort=id,asc&sort=endereco,asc&sort=descricao,asc'},
    {function:'cadastrarTiposTerceiro', type:'post', endPoint:'terceiroTipo', paramDefault:''},
    {function:'atualizarTiposTerceiro', type:'put', endPoint:'terceiroTipo', paramDefault:''},
    {function:'inativarTiposTerceiro', type:'patch', endPoint:'terceiroTipo/inativar', paramDefault:''},
    {function:'ativarTiposTerceiro', type:'patch', endPoint:'terceiroTipo/ativar', paramDefault:''},
    {function:'excluirTiposTerceiro', type:'delete', endPoint:'terceiroTipo', paramDefault:''},
    {function:'findTiposTerceiroById', type:'get', endPoint:'terceiroTipo', paramDefault:''},
    {function:'findAllTiposTerceiro', type:'get', endPoint:'terceiroTipo', paramDefault:''},
    {function:'searchTiposTerceiro', type:'get', endPoint:'terceiroTipo/pesquisar', paramDefault:''},

    // DIRETRIZ
    {function:'buscarDiretriz', type:'get', endPoint:'diretriz', paramDefault:'permiteInativos=true'},
    {function:'psqDiretriz', type:'get', endPoint:'diretriz', paramDefault:'permiteInativos=false&page=0&size=20&sort=id,asc&sort=sort=nome,asc'},
    {function:'cadastrarDiretriz', type:'post', endPoint:'diretriz', paramDefault:''},
    {function:'atualizarDiretriz', type:'put', endPoint:'diretriz', paramDefault:''},
    {function:'inativarDiretriz', type:'patch', endPoint:'diretriz/inativar', paramDefault:''},
    {function:'ativarDiretriz', type:'patch', endPoint:'diretriz/ativar', paramDefault:''},
    {function:'excluirDiretriz', type:'delete', endPoint:'diretriz', paramDefault:''},
    {function:'findDiretrizById', type:'get', endPoint:'diretriz', paramDefault:''},
    {function:'findAllDiretriz', type:'get', endPoint:'diretriz', paramDefault:''},
    {function:'searchDiretriz', type:'get', endPoint:'diretriz/pesquisar', paramDefault:''},

    // DVR
    {function:'buscarDvr', type:'get', endPoint:'condominio/dvr', paramDefault:'permiteInativos=true'},
    {function:'psqDvr', type:'get', endPoint:'condominio/dvr', paramDefault:'permiteInativos=false&page=0&size=20&sort=id,asc&sort=sort=nome,asc'},
    {function:'cadastrarDvr', type:'post', endPoint:'condominio/dvr', paramDefault:''},
    {function:'atualizarDvr', type:'put', endPoint:'condominio/dvr', paramDefault:''},
    {function:'inativarDvr', type:'patch', endPoint:'condominio/dvr/inativar', paramDefault:''},
    {function:'ativarDvr', type:'patch', endPoint:'condominio/dvr/ativar', paramDefault:''},
    {function:'excluirDvr', type:'delete', endPoint:'condominio/dvr', paramDefault:''},
    {function:'findDvrById', type:'get', endPoint:'condominio/dvr', paramDefault:''},
    {function:'findAllDvr', type:'get', endPoint:'condominio/dvr', paramDefault:''},
    {function:'searchDvr', type:'get', endPoint:'condominio/dvr/pesquisar', paramDefault:''},
    {function:'updateDvrsCams', type:'patch', endPoint:'condominio/dvr/updateDvrsCams', paramDefault:''},

    // BLOCOS
    {function:'buscarBloco', type:'get', endPoint:'bloco', paramDefault:'permiteInativos=true'},
    {function:'psqBloco', type:'get', endPoint:'bloco', paramDefault:'permiteInativos=false&page=0&size=20&sort=id,asc&sort=nome,asc'},
    {function:'cadastrarBloco', type:'post', endPoint:'bloco', paramDefault:''},
    {function:'atualizarBloco', type:'put', endPoint:'bloco', paramDefault:''},
    {function:'inativarBloco', type:'patch', endPoint:'bloco/inativar', paramDefault:''},
    {function:'ativarBloco', type:'patch', endPoint:'bloco/ativar', paramDefault:''},
    {function:'excluirBloco', type:'delete', endPoint:'bloco', paramDefault:''},
    {function:'findBlocoById', type:'get', endPoint:'bloco', paramDefault:''},
    {function:'findAllBloco', type:'get', endPoint:'bloco', paramDefault:''},
    {function:'searchBloco', type:'get', endPoint:'bloco/pesquisar', paramDefault:''},
    {function:'PsqBlocoCondominio', type:'get', endPoint:'bloco/condominio', paramDefault:'permiteInativos=false&page=0&size=20&sort=id,asc&sort=nome,asc&sort=descricao,asc'},
    {function:'findBlocoCondominioById', type:'get', endPoint:'condominio', paramDefault:''},

    // UNIDADES
    {function:'buscarUnidade', type:'get', endPoint:'unidade', paramDefault:'permiteInativos=true'},
    {function:'psqUnidade', type:'get', endPoint:'unidade', paramDefault:'permiteInativos=false&page=0&size=10&sort=id,asc&sort=nome,asc'},
    {function:'cadastrarUnidade', type:'post', endPoint:'unidade', paramDefault:''},
    {function:'atualizarUnidade', type:'put', endPoint:'unidade', paramDefault:''},
    {function:'inativarUnidade', type:'patch', endPoint:'unidade/inativar', paramDefault:''},
    {function:'ativarUnidade', type:'patch', endPoint:'unidade/ativar', paramDefault:''},
    {function:'excluirUnidade', type:'delete', endPoint:'unidade', paramDefault:''},
    {function:'findUnidadeById', type:'get', endPoint:'unidade', paramDefault:''},
    {function:'findAllUnidade', type:'get', endPoint:'unidade', paramDefault:''},
    {function:'searchUnidade', type:'get', endPoint:'unidade/pesquisar', paramDefault:''},
    {function:'psqUnidadeCondominio', type:'get', endPoint:'unidade/condominio', paramDefault:'permiteInativos=false&page=0&size=20&sort=id,asc&sort=nome,asc'},
    {function:'psqUnidadeBloco', type:'get', endPoint:'unidade/bloco', paramDefault:'permiteInativos=false&page=0&size=20&sort=id,asc&sort=nome,asc'},
    {function:'findUnidadeCondominioById', type:'get', endPoint:'unidade/condominio', paramDefault:''},
    {function:'findUnidadeBlocoById', type:'get', endPoint:'unidade/bloco', paramDefault:''},
    {function:'psqUnidadeTiposUnidades', type:'get', endPoint:'unidade/unidadeTipo', paramDefault:'permiteInativos=false&page=0&size=20&sort=id,asc&sort=nome,asc'},
    {function:'findUnidadeTiposUnidadesById', type:'get', endPoint:'unidade/unidadeTipo', paramDefault:''},
    {function:'findAllUnidadeTiposUnidades', type:'get', endPoint:'unidade/unidadeTipo', paramDefault:''},
    {function:'searchUnidadeTiposUnidades', type:'get', endPoint:'unidade/unidadeTipo/pesquisar', paramDefault:''},
    {function:'findAllUnidadeRestricao', type:'get', endPoint:'unidade/unidadeRestricao', paramDefault:''},
    {function:'searchUnidadeRestricao', type:'get', endPoint:'unidade/unidadeRestricao/pesquisar', paramDefault:''},
  
    // UNIDADE RESTRICAO
    {function:'cadastrarUnidadeRestricao', type:'post', endPoint:'unidadeRestricao', paramDefault:''},
    {function:'atualizarUnidadeRestricao', type:'put', endPoint:'unidadeRestricao', paramDefault:''},
    {function:'findUnidadeRestricaoById', type:'get', endPoint:'unidadeRestricao', paramDefault:''},
          
    // CONTROLADORAS
    {function:'psqControladoraModelos', type:'get', endPoint:'placa/getModelos', paramDefault:''},
    {function:'psqControladora', type:'get', endPoint:'placa', paramDefault:'permiteInativos=false&page=0&size=20&sort=id,asc&sort=nome,asc'},
    {function:'psqControladoraCondominio', type:'get', endPoint:'placa/condominio', paramDefault:'permiteInativos=false&page=0&size=20&sort=id,asc&sort=nome,asc'},
    {function:'inativarControladora', type:'patch', endPoint:'placa/inativar', paramDefault:''},
    {function:'ativarControladora', type:'patch', endPoint:'placa/ativar', paramDefault:''},
    {function:'findControladoraById', type:'get', endPoint:'placa', paramDefault:''},
    {function:'cadastrarControladora', type:'post', endPoint:'placa', paramDefault:''},
    {function:'atualizarControladora', type:'put', endPoint:'placa', paramDefault:''},
    {function:'PsqControladoraCondominio', type:'get', endPoint:'placa/condominio', paramDefault:'permiteInativos=false&page=0&size=20&sort=id,asc&sort=nome,asc'},
    {function:'findControladoraCondominioById', type:'get', endPoint:'placa/condominio', paramDefault:''},
    {function:'inoperarControladora', type:'patch', endPoint:'placa/inativarOperacao', paramDefault:''},
    {function:'operarControladora', type:'patch', endPoint:'placa/ativarOperacao', paramDefault:''},
    {function:'excluirControladora', type:'delete', endPoint:'placa', paramDefault:''},

    // CAMERAS
    {function:'buscarCamera', type:'get', endPoint:'camera', paramDefault:'permiteInativos=true'},
    {function:'psqCamera', type:'get', endPoint:'camera', paramDefault:'permiteInativos=false&page=0&size=20&sort=id,asc&sort=nome,asc'},
    {function:'cadastrarCamera', type:'post', endPoint:'camera', paramDefault:''},
    {function:'atualizarCamera', type:'put', endPoint:'camera', paramDefault:''},
    {function:'inativarCamera', type:'patch', endPoint:'camera/inativar', paramDefault:''},
    {function:'ativarCamera', type:'patch', endPoint:'camera/ativar', paramDefault:''},
    {function:'excluirCamera', type:'delete', endPoint:'camera', paramDefault:''},
    {function:'findCameraById', type:'get', endPoint:'camera', paramDefault:''},
    {function:'findAllCamera', type:'get', endPoint:'camera', paramDefault:''},
    {function:'searchCamera', type:'get', endPoint:'camera/pesquisar', paramDefault:''},
    {function:'PsqCameraCondominio', type:'get', endPoint:'camera/condominio', paramDefault:'permiteInativos=false&page=0&size=20&sort=id,asc&sort=nome,asc'},
    {function:'findCameraCondominioById', type:'get', endPoint:'camera/condominio', paramDefault:''},

    // ROTAS
    {function:'psqRotas', type:'get', endPoint:'rota', paramDefault:''},
    {function:'searchRotas', type:'get', endPoint:'rota/pesquisar', paramDefault:''},
    {function:'inativarRota', type:'patch', endPoint:'rota/inativar', paramDefault:''},
    {function:'ativarRota', type:'patch', endPoint:'rota/ativar', paramDefault:''},
    {function:'excluirRota', type:'delete', endPoint:'rota', paramDefault:''},
    {function:'psqRotaControladora', type:'get', endPoint:'rota/placa', paramDefault:'page=0&size=20&sort=id,asc&sort=nome,asc'},
    {function:'findRotaById', type:'get', endPoint:'rota', paramDefault:''},
    {function:'atualizarRota', type:'put', endPoint:'rota', paramDefault:''},
    {function:'psqRotaCondominio', type:'get', endPoint:'rota/condominio', paramDefault:''},
    {function:'findRotaCondominioById', type:'get', endPoint:'rota/condominio', paramDefault:''},
    {function:'findRotaControladoraById', type:'get', endPoint:'rota/placa', paramDefault:''},
    {function:'cadastrarRota', type:'post', endPoint:'rota', paramDefault:''},

    // DISPOSITIVOS
    {function:'psqDispositivos', type:'get', endPoint:'dispositivo', paramDefault:''},
    {function:'excluirDispositivo', type:'delete', endPoint:'dispositivo', paramDefault:''},
    {function:'inativarDispositivo', type:'patch', endPoint:'dispositivo/inativar', paramDefault:''},
    {function:'ativarDispositivo', type:'patch', endPoint:'dispositivo/ativar', paramDefault:''},
    {function:'psqDispositivoCondominio', type:'get', endPoint:'dispositivo/condominio', paramDefault:''},
    {function:'psqDispositivoModelos', type:'get', endPoint:'dispositivo/getModelos', paramDefault:''},
    {function:'psqDispositivoControladora', type:'get', endPoint:'dispositivo/placa', paramDefault:'permiteInativos=false&page=0&size=20&sort=id,asc&sort=nome,asc'},
    {function:'findDispositivoCondominioById', type:'get', endPoint:'dispositivo/condominio', paramDefault:''},
    {function:'findDispositivoControladoraById', type:'get', endPoint:'dispositivo/placa', paramDefault:''},
    {function:'findDispositivoById', type:'get', endPoint:'dispositivo', paramDefault:''},
    {function:'atualizarDispositivo', type:'put', endPoint:'dispositivo', paramDefault:''},
    {function:'cadastrarDispositivo', type:'post', endPoint:'dispositivo', paramDefault:''},
    {function:'psqDispositivoCamera', type:'get', endPoint:'camera', paramDefault:'permiteInativos=false&page=0&size=20&sort=id,asc&sort=nome,asc'},
    {function:'findDispositivoCameraById', type:'get', endPoint:'camera', paramDefault:''},

    // VEICULOS
    {function:'buscarVeiculo', type:'get', endPoint:'veiculo', paramDefault:'permiteInativos=true'},
    {function:'psqVeiculo', type:'get', endPoint:'veiculo', paramDefault:'permiteInativos=false&page=0&size=10&sort=id,asc&sort=placa,asc'},
    {function:'cadastrarVeiculo', type:'post', endPoint:'veiculo', paramDefault:''},
    {function:'atualizarVeiculo', type:'put', endPoint:'veiculo', paramDefault:''},
    {function:'inativarVeiculo', type:'patch', endPoint:'veiculo/inativar', paramDefault:''},
    {function:'ativarVeiculo', type:'patch', endPoint:'veiculo/ativar', paramDefault:''},
    {function:'excluirVeiculo', type:'delete', endPoint:'veiculo', paramDefault:''},
    {function:'findVeiculoById', type:'get', endPoint:'veiculo', paramDefault:''},
    {function:'findAllVeiculo', type:'get', endPoint:'veiculo', paramDefault:''},
    {function:'searchVeiculo', type:'get', endPoint:'veiculo/pesquisar', paramDefault:''},
    {function:'findAllVeiculoCores', type:'get', endPoint:'veiculo/getCores', paramDefault:''},
    {function:'findAllVeiculoTipos', type:'get', endPoint:'veiculo/getTipos', paramDefault:''},

    // ATENDIMENTO
    {function:'psqTipoAtendimento', type:'get', endPoint:'atendimento/atendimentoTipo', paramDefault:'permiteInativos=false&page=0&size=20&sort=id,asc&sort=nome,asc&sort=descricao,asc'},
    {function:'findAtendimentoTipoAtendimentosById', type:'get', endPoint:'atendimento/atendimentoTipo', paramDefault:''},
    {function:'psqAtendimentoCondominio', type:'get', endPoint:'condominio', paramDefault:'permiteInativos=false&page=0&size=20&sort=id,asc&sort=nome,asc&sort=descricao,asc'},
    {function:'findAtendimentoCondominioById', type:'get', endPoint:'condominio', paramDefault:''},
    {function:'findAtendimentoIntegradoraById', type:'get', endPoint:'atendimento/integradora', paramDefault:''},
    {function:'psqAtendimentoIntegradoraSlaves', type:'get', endPoint:'atendimento/integradora/getSlaves', paramDefault:''},
    {function:'psqAtendimentoPessoaFisica', type:'get', endPoint:'atendimento/pessoaFisica', paramDefault:'permiteInativos=false&page=0&size=20&sort=id,asc&sort=nome,asc&sort=descricao,asc'},
    {function:'findAtendimentoPessoaFisicaById', type:'get', endPoint:'atendimento/pessoaFisica', paramDefault:''},
    {function:'psqAtendimentoTerceiro', type:'get', endPoint:'atendimento/terceiro', paramDefault:'permiteInativos=false&page=0&size=20&sort=id,asc&sort=nome,asc&sort=descricao,asc'},
    {function:'findAtendimentoTerceiroById', type:'get', endPoint:'atendimento/terceiro', paramDefault:''},
    {function:'findAtendimentoTerceiroImagemById', type:'get', endPoint:'atendimento/terceiro/getImagens', paramDefault:''},
    {function:'atendimentoTerceiroImagemUpload', type:'patch', endPoint:'atendimento/terceiro/uploadImagem', paramDefault:''},
    {function:'psqAtendimentoCamera', type:'get', endPoint:'atendimento/camera', paramDefault:'permiteInativos=false&page=0&size=20&sort=id,asc&sort=nome, asc'},
    {function:'psqAtendimentoAcionamentos', type:'get', endPoint:'atendimento/dispositivo/listarAcionamento', paramDefault:''},
    {function:'psqAtendimentoReiniciar', type:'get', endPoint:'atendimento/dispositivo/listarReiniciar', paramDefault:''},
    {function:'atendimentoAtender', type:'post', endPoint:'atendimento/atender', paramDefault:''},
    {function:'atendimentoDispositivoAcionar', type:'patch', endPoint:'atendimento/dispositivo/acionar', paramDefault:''},
    {function:'atendimentoDispositivoReiniciar', type:'patch', endPoint:'atendimento/dispositivo/reiniciar', paramDefault:''},
    {function:'atendimentoIniciarSessao', type:'patch', endPoint:'atendimento/iniciarSessao', paramDefault:''},
    {function:'atendimentoEncerrarSessao', type:'patch', endPoint:'atendimento/encerrarSessao', paramDefault:''},

    // PERIFERICO
    {function:'inativarPeriferico', type:'patch', endPoint:'periferico/inativar', paramDefault:''},
    {function:'ativarPeriferico', type:'patch', endPoint:'periferico/ativar', paramDefault:''},
    {function:'excluirPeriferico', type:'delete', endPoint:'periferico', paramDefault:''},
    {function:'psqPerifericos', type:'get', endPoint:'periferico', paramDefault:'permiteInativos=false&page=0&size=10&sort=id,asc&sort=placa,asc'},
    {function:'psqPerifericoCondominio', type:'get', endPoint:'periferico/condominio', paramDefault:''},
    {function:'psqPerifericoRota', type:'get', endPoint:'periferico/rota', paramDefault:''},
    {function:'psqPerifericoPessoaFisica', type:'get', endPoint:'periferico/pessoaFisica', paramDefault:''},
    {function:'psqPerifericoDispositivo', type:'get', endPoint:'periferico/dispositivo', paramDefault:''},    
    {function:'psqPerifericoTerceiro', type:'get', endPoint:'periferico/terceiro', paramDefault:''},    
    {function:'psqPerifericoVeiculo', type:'get', endPoint:'periferico/veiculo', paramDefault:''},    
    //{function:'cadastrarPerifericoSerial', type:'put', endPoint:'periferico/cadastrarSerial', paramDefault:''},

    // TIPOS ATENDIMENTO
    {function:'buscarTiposAtendimentos', type:'get', endPoint:'atendimentoTipo', paramDefault:'permiteInativos=true'},
    {function:'psqTiposAtendimentos', type:'get', endPoint:'atendimentoTipo', paramDefault:'permiteInativos=false&page=0&size=20&sort=id,asc&sort=descricao,asc'},
    {function:'cadastrarTiposAtendimentos', type:'post', endPoint:'atendimentoTipo', paramDefault:''},
    {function:'atualizarTiposAtendimentos', type:'put', endPoint:'atendimentoTipo', paramDefault:''},
    {function:'inativarTiposAtendimentos', type:'patch', endPoint:'atendimentoTipo/inativar', paramDefault:''},
    {function:'ativarTiposAtendimentos', type:'patch', endPoint:'atendimentoTipo/ativar', paramDefault:''},
    {function:'excluirTiposAtendimentos', type:'delete', endPoint:'atendimentoTipo', paramDefault:''},
    {function:'findTiposAtendimentosById', type:'get', endPoint:'atendimentoTipo', paramDefault:''},
    {function:'findAllTiposAtendimentos', type:'get', endPoint:'atendimentoTipo', paramDefault:''},
    {function:'searchTiposAtendimentos', type:'get', endPoint:'atendimentoTipo/pesquisar', paramDefault:''},

    // CONFIGURAÇÃO
    {function:'psqConfiguracao', type:'get', endPoint:'configuracao', paramDefault:''},
    {function:'searchConfiguracao', type:'get', endPoint:'configuracao/pesquisar', paramDefault:''},
    {function:'findConfiguracao', type:'get', endPoint:'configuracao/getConteudo', paramDefault:''},
    {function:'configurarConfiguracao', type:'patch', endPoint:'configuracao/configurar', paramDefault:''},

    // TIMEZONE
    {function:'psqTimezone', type:'get', endPoint:'util/timezone', paramDefault:''},
    {function:'findTimezoneById', type:'get', endPoint:'util/timezone', paramDefault:''},

    // relatorio

    {function:'getRelatorio', type:'get', endPoint:'evento/getRelatorioEventos', paramDefault:''},



  ];

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private http: HttpClient,) { }

  patchImage(funcao: string, params: string, file: File | null) {
    const req = API.find( api => api.function === funcao );


    const formData = new FormData();
    formData.append('imagem', file, file.name);

    if(req.type === "patch"){     
      // const requ = new HttpRequest('PATCH', PATH + req.endPoint + params, formData,{
      //   reportProgress: true,
      //   responseType: 'json'
      // });

      return this.http.patch(PATH + req.endPoint + params, formData)

    }
    return null;
  }

  requestAPI(funcao: string, DTOin?:any, params?:string, pesquisa?:string):Observable<any> {

    const req = API.find( api => api.function === funcao );

    if(req.type === 'patch'){
      if(DTOin){
        return this.http.patch(PATH + req.endPoint, JSON.stringify(DTOin));
      }
      return this.http.patch(PATH + req.endPoint, null);

    } else if(req.type === 'post'){

      if(DTOin){
        return this.http.post(PATH + req.endPoint, JSON.stringify(DTOin));
      }
      return this.http.post(PATH + req.endPoint, null);

    }else if(req.type === 'get'){
      let endpoint = req.endPoint;
      if(pesquisa){
        endpoint = endpoint + '/pesquisar?src=' + pesquisa + '&';
      }
      if(params){
        if(!pesquisa){
          endpoint = endpoint + '?';
        }
        return this.http.get(PATH + endpoint + params + '&permiteInativos=true');
      }
      if(req.paramDefault){
        endpoint = endpoint + '?';
        return this.http.get(PATH + endpoint + req.paramDefault );
      }
      return this.http.get(PATH + endpoint );

    }else if(req.type = 'put') {
      if(DTOin){
        return this.http.put(PATH + req.endPoint, JSON.stringify(DTOin));
      }
      return this.http.put(PATH + req.endPoint, null);
    }

  }

  requestById(funcao: string, params:string, permiteInativos:boolean) :Observable<any>{
    const req = API.find( api => api.function === funcao );
    let endpoint = req.endPoint;
    return this.http.get(PATH + endpoint + '/' + params + '?permiteInativos=' + permiteInativos);
  }

  deleteByDados(funcao: string, dados: any) :Observable<any>{
    const req = API.find( api => api.function === funcao );
    let endpoint = req.endPoint;
    return this.http.delete(PATH + endpoint, {params: dados});
  }

  deleteById(funcao: string, id:number) :Observable<any>{
    const req = API.find( api => api.function === funcao );
    let endpoint = req.endPoint;
    return this.http.delete(PATH + endpoint + '/' + id);
  }

  patchById(funcao: string, id:number) :Observable<any>{
    const req = API.find( api => api.function === funcao );
    return this.http.patch(PATH + req.endPoint, JSON.stringify(new IdDTOIn(id)));
  }

  findById(funcao: string, id:number, permiteInativos:boolean, params?: string) :Observable<any>{
    const req = API.find( apiFind => apiFind.function === funcao );
    const endpoint = req.endPoint;
    return this.http.get(PATH + endpoint + '/' + id + '?permiteInativos=' + permiteInativos + '' + (params || ''));
  }

  getAll(funcao: string, params?:string, permiteInativos?:boolean):Observable<any> {
    const req = API.find( api => api.function === funcao );
    let endpoint = req.endPoint;

    let caminho = PATH + endpoint + '?permiteInativos=' + permiteInativos;
    if(params){
      caminho = PATH + endpoint + '?' + params + '&permiteInativos=' + permiteInativos;
    }
    return this.http.get(caminho);
  }

  searchAll(funcao: string, textoPesquisa:string, params?:string, permiteInativos?:boolean):Observable<any> {
    const req = API.find( api => api.function === funcao );
    const endpoint = req.endPoint;

    let caminho = PATH + endpoint + '?src=' + textoPesquisa + '&permiteInativos=' + permiteInativos;
    if(params){
      caminho = PATH + endpoint + '?src=' + textoPesquisa + '&' + params + '&permiteInativos=' + permiteInativos;
    }
    return this.http.get(caminho);
  }

  requestAC(funcao: string, DTOin?:any, params?:string, pesquisa?:string):Observable<any> {

    const req = API.find( api => api.function === funcao );

      let endpoint = req.endPoint;
      if(pesquisa){
        endpoint = endpoint + '/pesquisar?src=' + pesquisa + '&';
      }
      if(params){
        if(!pesquisa){
          endpoint = endpoint + '?';
        }
        return this.http.get(PATH + endpoint + params + '&permiteInativos=true')
        .pipe(map(resp => {
          if (resp['empty'] == true) {
            return [];
          } else {
            if(resp['content']){
              return resp['content']
            }
            return resp;
          }
        })
        );
      }
      return this.http.get(PATH + endpoint ).pipe(map(resp => {
        if (resp['empty'] == true) {
          return [];
        } else {
          if(resp['content']){
            return resp['content']
          }
          return resp;
        }
      })
      );
  }

  public getIPAddress(url: string)
  {
    url = 'http://'+ url;
    return this.http.get<{ip:string}>(url);
  }
}
