import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap, startWith, map } from 'rxjs/operators';
import { ContentDTO } from 'src/app/core/models/ContentDTO';
import { DispositivoDTO } from 'src/app/core/models/Dispositivo';
import { HttpService } from 'src/app/core/services/http.service';
import { MensagemService } from 'src/app/core/services/mensagem.service';

@Component({
  selector: 'campo-dispositivo',
  templateUrl: './campo-dispositivo.component.html',
  styleUrls: ['./campo-dispositivo.component.scss']
})
export class CampoDispositivoComponent implements OnInit {

  condominio: string;

  campo: string = "dispositivo";
  controlname: string = "dispositivo";
  searchable: boolean = false;
  @Input() clearable: boolean = false;
  carregamentoInicial = false;
  exibeModelo: boolean = true;

  @Input() formGroupName: string;
  @Input() endPointPsq: string;
  @Input() permiteInativos: boolean = true;
  @Input() endPointFindById: string;
  @Output() campoEmit = new EventEmitter<any>();
  @ViewChild(NgSelectComponent) ngSelect: NgSelectComponent;
  @Input() selecionaPrimeiro: boolean = true;
  @Output() listaEmit = new EventEmitter<DispositivoDTO[]>();

  listaDispositivos: DispositivoDTO[] = [];


  form: FormGroup;
  items$: Observable<any>;
  minimoCaracteresBusca = 2;
  pesquisando = false;
  searchInput$ = new Subject<string>();
  idSelecionado: string;
  idIni: number = 0;

  modelos: string[] = [];
  tipo: string = '';
  forceChange: string = '';

  constructor(private httpService: HttpService,
    private rootFormGroup: FormGroupDirective,
    private msg: MensagemService,) { }

  ngOnInit(): void {
    this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
    this.items$ =
      this.searchInput$.pipe(
        startWith(''),
        distinctUntilChanged(),
        debounceTime(100),
        tap(() => this.pesquisando = true),
        switchMap(pesq => {
          if (this.forceChange == 'ignorarable' || this.forceChange == 'ableignorar') {
            pesq = '';
          }
          return this.getData(pesq).pipe(
            catchError(() => of([])), // empty list on error
            tap(data => {
              this.pesquisando = false;
              if (this.selecionaPrimeiro || this.idIni > 0) {
                this.idSelecionado = data[0].id;
                if (this.idIni > 0) {
                  let idSel = data.find(x => x.id == this.idIni);
                  if (idSel) {
                    this.idSelecionado = idSel.id;
                  }
                  this.idIni = 0;
                }
              }
            }),
            map(data => {
              if (this.tipo != '') {
                data = data.filter(x => x.identificadoresSuportados.includes(this.tipo));
              }
              this.listaDispositivos = data;
              return data;
            })
          )
        })
      );
  }

  trackByFn(item: ContentDTO) {
    return item.id;
  }

  getData(searchTerm: string): Observable<any> {
    let params = `permiteInativos=${this.permiteInativos}&page=0&size=50&sort=descricao,asc&sort=id,asc`;
    if (this.condominio) {
      params = `condominio=${this.condominio}&permiteInativos=${this.permiteInativos}&page=0&size=50&sort=descricao,asc&sort=id,asc`;
    }
    return this.httpService.requestAC(this.endPointPsq, null, params, null);
  }

  onChange($event?) {
    let camera = "";
    if ($event.camera != null && $event.camera != undefined) {
      camera = $event.camera.id;
    }
    this.campoEmit.emit({ dispositivo: this.idSelecionado, modelo: $event.modelo, camera: camera });
    this.listaEmit.emit(this.listaDispositivos);
  }

  clear() {
    this.ngSelect.clearModel();
  }

  setSelectedId(id: number) {
    if (this.carregamentoInicial && this.ngSelect.items == null) {
      this.idIni = id;
      return;
    }
    let idSel = this.ngSelect.items.find(x => x.id == id);
    if (idSel) {
      this.idSelecionado = idSel.id;
    } else {
      this.httpService.findById(this.endPointFindById, id, true)
        .pipe().subscribe(
          data => {
            const millis: number = data.dataExpiracao;
            let dispositivo = data as DispositivoDTO;
            this.items$ = of([{ id: dispositivo.id, nome: dispositivo.descricao, ativo: dispositivo.ativo }]);
            if (this.ngSelect.items.length > 0) {
              this.idSelecionado = this.ngSelect.items[0].id;
            }
          },
          err => {
            this.msg.openSnackBar("erro", err['message'], err['status']);
          }
        );


    }
  }

  setCondominio(condominio: string) {
    this.condominio = condominio;
    this.carregamentoInicial = true;
    this.idSelecionado = null;
    this.ngSelect.clearModel();
    if (this.forceChange == 'ignorarable') {
      this.forceChange = 'ableignorar'
    } else {
      this.forceChange = 'ignorarable'
    }
    this.searchInput$.next(this.forceChange);
  }

  filtraModelos(tipo: string) {
    this.tipo = tipo;
    this.setCondominio(this.condominio)
  }

}


