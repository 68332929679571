import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BaseComponent } from './views/layout/base/base.component';
import { AuthGuard } from './core/guard/auth.guard';
import { ErrorPageComponent } from './views/pages/error-page/error-page.component';
import { Roles } from './core/enums/roles.enum';

const routes: Routes = [
  { path:'auth', loadChildren: () => import('./views/pages/auth/auth.module').then(m => m.AuthModule) },
  {
    path: '',
    component: BaseComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'main',
        loadChildren: () => import('./views/able-pages/main/main.module').then(m => m.MainModule)
      },
      {
        path: 'atendimento',
        loadChildren: () => import('./views/able-pages/atendimento/atendimento.module').then(m => m.AtendimentoModule),
        data:{
          allowedRoles: [Roles.ATENDIMENTO, Roles.ATENDIMENTO_A, Roles.ATENDIMENTO_D_A, Roles.ATENDIMENTO_D_R],
        }
      },
      {
        path: 'atendimento/:id',
        loadChildren: () => import('./views/able-pages/atendimento/atendimento.module').then(m => m.AtendimentoModule),
        data:{
          allowedRoles: [Roles.ATENDIMENTO, Roles.ATENDIMENTO_A, Roles.ATENDIMENTO_D_A, Roles.ATENDIMENTO_D_R],
        }
      },
      {
        path: 'bicicletas',
        loadChildren: () => import('./views/able-pages/bicicletas/bicicletas.module').then(m => m.BicicletasModule),
      },
      {
        path: 'blocos',
        loadChildren: () => import('./views/able-pages/blocos/blocos.module').then(m => m.BlocosModule),
        data:{
          allowedRoles: [Roles.BLOCO_I, Roles.BLOCO_R, Roles.BLOCO_W, Roles.BLOCO ]
        }
      },
      {
        path: 'camera',
        loadChildren: () => import('./views/able-pages/camera/camera.module').then(m => m.CameraModule),
        data:{
          allowedRoles: [Roles.CAMERA_I, Roles.CAMERA_R, Roles.CAMERA_W, Roles.CAMERA ]
        }
      },
      {
        path: 'condominio',
        loadChildren: () => import('./views/able-pages/condominio/condominio.module').then(m => m.CondominioModule),
        data:{
          allowedRoles: [Roles.CONDOMINIO, Roles.CONDOMINIO_R, Roles.CONDOMINIO_W, Roles.CONDOMINIO_I],
        }
      },
      {
        path: 'configuracoes',
        loadChildren: () => import('./views/able-pages/configuracoes/configuracoes.module').then(m => m.ConfiguracoesModule),
        data:{
          allowedRoles: [Roles.CONFIGURACAO_R, Roles.CONFIGURACAO_W, Roles.CONFIGURACAO ]
        }
      },
      {
        path: 'diretriz',
        loadChildren: () => import('./views/able-pages/diretriz/diretriz.module').then(m => m.DiretrizModule),
        data:{
          allowedRoles: [Roles.DIRETRIZ, Roles.DIRETRIZ_R, Roles.DIRETRIZ_W, Roles.DIRETRIZ_I],
        }
      },
      {
        path: 'dispositivo',
        loadChildren: () => import('./views/able-pages/dispositivo/dispositivo.module').then(m => m.DispositivoModule),
        data:{
          allowedRoles: [Roles.DISPOSITIVO, Roles.DISPOSITIVO_R, Roles.DISPOSITIVO_W, Roles.DISPOSITIVO_I],
        }
      },
      {
        path: 'grupo',
        loadChildren: () => import('./views/able-pages/grupo/grupo.module').then(m => m.GrupoModule),
        data:{
          allowedRoles: [Roles.GRUPO, Roles.GRUPO_R, Roles.GRUPO_W, Roles.GRUPO_I],
        }
      },
      {
        path: 'integradora',
        loadChildren: () => import('./views/able-pages/integradora/integradora.module').then(m => m.IntegradoraModule),
        data:{
          allowedRoles: [Roles.INTEGRADORA, Roles.INTEGRADORA_R, Roles.INTEGRADORA_W, Roles.INTEGRADORA_I],
        }
      },
      {
        path: 'ip',
        loadChildren: () => import('./views/able-pages/ip/ip.module').then(m => m.IpModule),
        data:{
          allowedRoles: [Roles.IP, Roles.IP_R, Roles.IP_W, Roles.IP_I],
        }
      },
      {
        path: 'morador',
        loadChildren: () => import('./views/able-pages/pessoa-fisica/pessoa-fisica.module').then(m => m.PessoaFisicaModule),
        data:{
          allowedRoles: [Roles.PESSOA_FISICA_I, Roles.PESSOA_FISICA_R, Roles.PESSOA_FISICA_W, Roles.PESSOA_FISICA ]
        }
      },
      {
        path: 'pets',
        loadChildren: () => import('./views/able-pages/pets/pets.module').then(m => m.PetsModule),
      },
      {
        path: 'placa',
        loadChildren: () => import('./views/able-pages/controladoras/controladoras.module').then(m => m.ControladorasModule),
        data:{
          allowedRoles: [Roles.PLACA_I, Roles.PLACA_R, Roles.PLACA_W, Roles.PLACA ]
        }
      },
      {
        path: 'relatorio',
        loadChildren: () => import('./views/able-pages/relatorio/relatorio.module').then(m => m.RelatorioModule),
        data:{
          allowedRoles: [Roles.RELATORIO, Roles.RELATORIO_R, Roles.RELATORIO_W, Roles.RELATORIO_I],
        }
      },
      {
        path: 'visitantes',
        loadChildren: () => import('./views/able-pages/terceiros/terceiros.module').then(m => m.TerceirosModule),
        data:{
          allowedRoles: [Roles.TERCEIRO_I, Roles.TERCEIRO_R, Roles.TERCEIRO_W, Roles.TERCEIRO ]
        }
      },
      {
        path: 'tipos-atendimento',
        loadChildren: () => import('./views/able-pages/tipos-atendimento/tipos-atendimento.module').then(m => m.TiposAtendimentoModule),
        data:{
          allowedRoles: [Roles.ATENDIMENTO_TIPO_R, Roles.ATENDIMENTO_TIPO_W, Roles.ATENDIMENTO_TIPO ]
        }
      },
      {
        path: 'tipos-morador',
        loadChildren: () => import('./views/able-pages/tipos-pessoa/tipos-pessoa.module').then(m => m.TiposPessoaModule),
        data:{
          allowedRoles: [Roles.ATENDIMENTO_TIPO_R, Roles.ATENDIMENTO_TIPO_W, Roles.ATENDIMENTO_TIPO ]
        }
      },
      {
        path: 'tipos-visitantes',
        loadChildren: () => import('./views/able-pages/tipos-terceiros/tipos-terceiros.module').then(m => m.TiposTerceirosModule),
        data:{
          allowedRoles: [Roles.MASTER, Roles.MASTER, Roles.MASTER, Roles.MASTER ]
        }
      },
      {
        path: 'tipos-unidades',
        loadChildren: () => import('./views/able-pages/tipos-unidades/tipos-unidades.module').then(m => m.TiposUnidadesModule),
        data:{
          allowedRoles: [Roles.UNIDADE_TIPO_I, Roles.UNIDADE_TIPO_R, Roles.UNIDADE_TIPO_W, Roles.UNIDADE_TIPO]
        }
      },
      {
        path: 'unidade',
        loadChildren: () => import('./views/able-pages/unidade/unidade.module').then(m => m.UnidadeModule),
        data:{
          allowedRoles: [Roles.BLOCO, Roles.BLOCO_R, Roles.BLOCO_W, Roles.BLOCO_I],
        }
      },
      {
        path: 'usuintegradora',
        loadChildren: () => import('./views/able-pages/usuario-integradora/usuintegradora.module').then(m => m.UsuintegradoraModule),
        data:{
          allowedRoles: [Roles.USUARIO, Roles.USUARIO_R, Roles.USUARIO_W, Roles.USUARIO_I],
        }
      },
      // {
      //   path: 'veiculo',
      //   loadChildren: () => import('./views/able-pages/veiculo/veiculo.module').then(m => m.VeiculoModule),
      //   data:{
      //     allowedRoles: [Roles.VEICULO, Roles.VEICULO_R, Roles.VEICULO_W, Roles.VEICULO_I],
      //   }
      // },
      {
        path: 'rotas',
        loadChildren: () => import('./views/able-pages/rotas/rotas.module').then(m => m.RotasModule),
        data:{
          allowedRoles: [Roles.ROTA, Roles.ROTA_R, Roles.ROTA_W, Roles.ROTA_I],
        }
      },{
        path: 'restricoes',
        loadChildren: () => import('./views/able-pages/restricoes/restricoes.module').then(m => m.RestricoesModule),
        data:{
          allowedRoles: [Roles.UNIDADE_RESTRICAO, Roles.UNIDADE_RESTRICAO_R, Roles.UNIDADE_RESTRICAO_W, Roles.UNIDADE_RESTRICAO_I],
        }
      },
      {
        path: 'perifericos',
        loadChildren: () => import('./views/able-pages/perifericos/perifericos.module').then(m => m.PerifericosModule),
        data:{
          allowedRoles: [Roles.PERIFERICO, Roles.PERIFERICO_R, Roles.PERIFERICO_W, Roles.PERIFERICO_I],
        }
      },
      // {
      //   path: 'teste',
      //   loadChildren: () => import('./views/pages/ui-components/ui-components.module').then(m => m.UiComponentsModule),
      //   data:{
      //     allowedRoles: [Roles.PERIFERICO, Roles.PERIFERICO_R, Roles.PERIFERICO_W, Roles.PERIFERICO_I],
      //   }
      // },
      { path: '', redirectTo: 'main', pathMatch: 'full' }, 
      // { path: '**', redirectTo: 'dashboard', pathMatch: 'full' }
    ]
  },
  { 
    path: 'error',
    component: ErrorPageComponent,
    data: {
      'type': 404,
      'title': 'Página não encontrada',
      'desc': 'A página que está tentando acessar não existe!'
    }
  },
  { 
    path: 'acessonegado',
    component: ErrorPageComponent,
    data: {
      'type': 403,
      'title': 'Acesso negado',
      'desc': 'Você não tem permissão pra acessar este conteúdo!'
    }
  },
  {
    path: 'error/:type',
    component: ErrorPageComponent
  },
  { path: '**', redirectTo: 'error', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }