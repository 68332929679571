import { finalize } from 'rxjs/operators';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MensagemConfirmaService } from './../../../../core/services/mensagem-confirma.service';
import { HttpService } from './../../../../core/services/http.service';
import { LoadingService } from './../../../../core/services/loading.service';
import { MensagemService } from './../../../../core/services/mensagem.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';
import { CustomValidators } from 'src/app/core/util/custom-validators';

@Component({
  selector: 'app-dados-usuario',
  templateUrl: './dados-usuario.component.html',
  styleUrls: ['./dados-usuario.component.scss']
})
export class DadosUsuarioComponent implements OnInit {
  
  constructor(
    public activeModal: NgbActiveModal, 
    private fb: FormBuilder, 
    private msgConfirma: MensagemConfirmaService, 
    private httpSerice: HttpService,
    private loadingService: LoadingService,
    private msg: MensagemService
  ) { }
  tipo: any;
  form: FormGroup;

  ngOnInit(): void {
    this.carregaForm();
    this.buscarUsuario();
    if (this.tipo == 'query') {
      this.form.disable(); 
    }
  }
  carregaForm() {
    this.form = this.fb.group({
      cpf: [''],
      nome:  ['', [Validators.required]],
      apelido:  ['', [Validators.required]],
      email1: ['', [Validators.required, Validators.email]],
      email2:  ['', [Validators.required, Validators.email]],
      telefone1:  ['', [Validators.required]],
      telefone2:  ['', [Validators.required]],
      id:  [''],
      grupo: [''],
    });
  }

  cancelar(e) {
    e.preventDefault();

    this.msgConfirma.openConfirmDialog("Deseja realmente cancelar alteração ?", (result: any) => {
      if (result.isConfirmed) {
        this.activeModal.close();
      }
    }, "warning", "Cancelar", "Sim", "Não"
    );
  }

  buscarUsuario() {
    var spinnerRef = this.loadingService.start("Carregando...");
    this.httpSerice.requestById(`buscarUsuario`,sessionStorage.getItem('id'), true)
      .pipe(finalize(() => {
        this.loadingService.stop(spinnerRef);
      })).subscribe(
        data => {
          this.form.setValue(data);
        },
        err => {
          this.activeModal.close();
          this.msg.openSnackBar('erro', err['message'], err['status']);
        }
      );
  }

  alterar(e) {
    e.preventDefault();
    this.msgConfirma.openConfirmDialog("Deseja realmente alterar dados ?", (result: any) => {
      if (result.isConfirmed) {
        this.form.disable();
        var spinnerRef = this.loadingService.start("Carregando...");
        const dados = this.form.value
        dados.grupo = dados.grupo.id;
        delete dados.cpf;
        this.httpSerice.requestAPI('atualizarUsuario', dados)
          .pipe(finalize(() => {
            this.loadingService.stop(spinnerRef);
            this.form.enable();
          })).subscribe(
            data => {
              sessionStorage['apelido'] = this.form.get('apelido').value;
              this.activeModal.close(sessionStorage['apelido']);
            },
            err => {
              this.msg.openSnackBar('erro', err['message'], err['status']);
            }
          );
      }
    }, "warning", "Cancelar", "Sim", "Não"
    );
  }

}
