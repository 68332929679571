import { FocusFormDirective } from './focus-form.directive';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';



@NgModule({
  declarations: [FocusFormDirective],
  exports: [FocusFormDirective]
})
export class FocusFormModule { }
