import { MensagemService } from './../../../../core/services/mensagem.service';
import { HttpService } from 'src/app/core/services/http.service';
import { LoadingService } from './../../../../core/services/loading.service';
import { finalize } from 'rxjs/operators';
import { MensagemConfirmaService } from './../../../../core/services/mensagem-confirma.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DvrDTO } from 'src/app/core/models/DvrDTO';

@Component({
  selector: 'app-dvrs-manipulate',
  templateUrl: './dvrs-manipulate.component.html',
  styleUrls: ['./dvrs-manipulate.component.scss']
})
export class DvrsManipulateComponent implements OnInit, AfterViewInit {

  condominio: number;
  dvr: DvrDTO;
  formDados: FormGroup;
  tipo: string;
  dvrEditar: number;

  @ViewChild('campoNome') campoNome: ElementRef;

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private msgConfirma: MensagemConfirmaService,
    private httpService: HttpService,
    private loadingService: LoadingService,
    private msg: MensagemService
  ) { }

  ngOnInit(): void {
    this.inicializaForms();
  }

  ngAfterViewInit() {
    if (this.tipo === 'read' || this.tipo === 'edit') {
      this.carregaDVR();
    }
    this.campoNome.nativeElement.focus();
  }

  inicializaForms() {
    this.formDados = this.fb.group({
      endereco: ['', [Validators.required]],
      nome: ['', [Validators.required]],
      porta: ['', [Validators.required]],
      senha: ['', [Validators.required]],
      usuario: ['', [Validators.required]],
    });

    if (this.tipo === 'read') {
      this.formDados.disable();
    }
  }

  carregaDVR() {
    const spinnerRef = this.loadingService.start('Carregando...');
    this.httpService.findById('findDvrById', this.dvrEditar, true)
      .pipe(finalize(() => {
        this.loadingService.stop(spinnerRef);
      })).subscribe(
        data => {
          this.dvr = data as DvrDTO;
          this.condominio = this.condominio ? this.condominio : data.condominio.id;
          this.preencheForm();
        },
        err => {
          this.activeModal.close();
          this.msg.openSnackBar("erro", err['message'], err['status']);
        }
      );
  }

  preencheForm() {
    this.formDados.get('endereco').setValue(this.dvr.endereco);
    this.formDados.get('nome').setValue(this.dvr.nome);
    this.formDados.get('porta').setValue(this.dvr.porta);
    this.formDados.get('senha').setValue(this.dvr.senha);
    this.formDados.get('usuario').setValue(this.dvr.usuario);

    if (this.tipo === 'read') {
      this.formDados.disable();
    }
  }

  cancelar(e) {
    e.preventDefault();

    if (!this.isChanged()) {
      this.activeModal.close();
      return;
    }

    this.msgConfirma.openConfirmDialog(
      this.tipo === 'edit' ? 'Deseja realmente cancelar edição ?' : 'Deseja realmente cancelar cadastro ?', (result: any) => {
        if (result.isConfirmed) {
          this.activeModal.close();
        }
      }, 'warning', 'Cancelar', 'Sim', 'Não'
    );
  }

  isChanged(): boolean {
    const dvrInicialAux: DvrDTO = {};
    dvrInicialAux.endereco = this.dvr ? this.dvr.endereco : '';
    dvrInicialAux.nome = this.dvr ? this.dvr.nome : '';
    dvrInicialAux.porta = this.dvr ? this.dvr.porta : 0;
    dvrInicialAux.senha = this.dvr ? this.dvr.senha : '';
    dvrInicialAux.usuario = this.dvr ? this.dvr.usuario : '';

    const dvrAlteradoAux: DvrDTO = this.formDados.value;

    return !(JSON.stringify(dvrInicialAux) === JSON.stringify(dvrAlteradoAux));
  }

  incluir() {
    if (this.verificaFormValid()) {
      this.msgConfirma.openConfirmDialog('Deseja realmente inserir o DVR ?', (result: any) => {
        if (result.isConfirmed) {
          this.formDados.disable();
          const spinnerRef = this.loadingService.start('Carregando...');
          this.dvr = this.formDados.value;
          this.dvr.condominio = this.condominio;
          this.httpService.requestAPI('cadastrarDvr', this.dvr)
            .pipe(finalize(() => {
              this.loadingService.stop(spinnerRef);
              this.activeModal.close();
            })).subscribe(
              data => { },
              err => {
                this.msg.openSnackBar("erro", err['message'], err['status']);
              }
            );
        }
      }, 'warning', 'Inserir', 'Sim', 'Não'
      );
    }
  }

  editar() {
    if (!this.isChanged()) {
      this.trataMsgEditarSemAlteracao();
      return;
    }

    if (this.verificaFormValid()) {
      this.msgConfirma.openConfirmDialog('Deseja realmente editar o DVR ?', (result: any) => {
        if (result.isConfirmed) {
          this.formDados.disable();
          const spinnerRef = this.loadingService.start('Carregando...');
          this.dvr = this.formDados.value;
          this.dvr.id = this.dvrEditar;
          this.dvr.condominio = this.condominio;
          this.httpService.requestAPI('atualizarDvr', this.dvr)
            .pipe(finalize(() => {
              this.loadingService.stop(spinnerRef);
              this.activeModal.close();
            })).subscribe(
              data => { },
              err => {
                this.msg.openSnackBar("erro", err['message'], err['status']);
              }
            );
        }
      }, 'warning', 'Editar', 'Sim', 'Não'
      );
    }
  }

  trataMsgEditarSemAlteracao() {
    this.msgConfirma.openInfoDialog('Deseja realmente editar o DVR ?', (result: any) => {
    }, 'info', '', 'Ok'
    );
  }

  public visualizar() {
    this.activeModal.close();
  }

  verificaFormValid() {
    if (this.formDados.valid) {
      return true;
    } else {
      return false;
    }
  }

  getActionButton() {
    if (this.tipo === 'edit') {
      return 'Editar';
    } else if (this.tipo === 'read') {
      return 'Ok';
    }
    return 'Incluir';
  }

  execute() {
    if (this.tipo === 'edit') {
      this.editar();
      return
    }

    if (this.tipo === 'read') {
      this.visualizar();
      return;
    }

    if (this.tipo === 'include') {
      this.incluir();
      return;
    }
  }

  getActionFunction() {
    if (this.tipo === 'edit') {
      return 'editar()';
    } else if (this.tipo === 'read') {
      return 'ok()';
    }
    return 'incluir()';
  }

  get endereco() { return this.formDados.get('endereco'); }
  get nome() { return this.formDados.get('nome'); }
  get porta() { return this.formDados.get('porta'); }
  get senha() { return this.formDados.get('senha'); }
  get usuario() { return this.formDados.get('usuario'); }

}
