import { Router } from '@angular/router';
import { concat, finalize } from 'rxjs/operators';
import { MensagemService } from './../../../../core/services/mensagem.service';
import { HttpService } from 'src/app/core/services/http.service';
import { LoadingService } from './../../../../core/services/loading.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MensagemConfirmaService } from './../../../../core/services/mensagem-confirma.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-alterar-senha',
  templateUrl: './alterar-senha.component.html',
  styleUrls: ['./alterar-senha.component.scss']
})
export class AlterarSenhaComponent implements OnInit {
  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private msgConfirma: MensagemConfirmaService,
    private httpSerice: HttpService,
    private loadingService: LoadingService,
    private msg: MensagemService,
    private router: Router,
  ) { }
  form: FormGroup
  regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}.*$/;

  ngOnInit(): void {
    this.carregaForm();
  }
  carregaForm() {
    this.form = this.fb.group({
      senhaAtual: ['', [Validators.required]],
      senhaNova: ['', [Validators.required, Validators.pattern(this.regex)]],
    });
  }

  cancelar(e) {
    e.preventDefault();

    this.msgConfirma.openConfirmDialog("Deseja realmente cancelar alteração ?", (result: any) => {
      if (result.isConfirmed) {
        this.activeModal.close();
      }
    }, "warning", "Cancelar", "Sim", "Não"
    );
  }

  alterar(e) {
    e.preventDefault();

    this.msgConfirma.openConfirmDialog("Deseja realmente alterar a senha ?", (result: any) => {
      if (result.isConfirmed) {
        this.form.disable();
        var spinnerRef = this.loadingService.start("Carregando...");
        const dados = this.form.value
        dados.id = sessionStorage.getItem('id');
        console.log(dados);

        this.httpSerice.requestAPI('alterarSenha', dados)
          .pipe(finalize(() => {
            this.loadingService.stop(spinnerRef);
            this.form.enable();
          })).subscribe(
            data => {
              localStorage.removeItem('isLoggedin');
              this.httpSerice.requestAPI('logout')
                .pipe(finalize(() => {
                  this.loadingService.stop(spinnerRef);
                })).subscribe(
                  data => {
                    localStorage.removeItem('ablejwt');
                    sessionStorage.removeItem('ablejwt');
                    this.router.navigate(['/auth/login']);
                  },
                  err => {
                    this.msg.openSnackBar('erro', err['message'], err['status']);
                  }
                );
              this.activeModal.close();
            },
            err => {
                this.msg.openSnackBar2("erro", err);
            }
          );
      }
    }, "warning", "Confirmação", "Sim", "Não"
    );
  }

  
  get senhaAtual()      { return this.form.get('senhaAtual'); }
  get senhaNova()      { return this.form.get('senhaNova'); }
}
