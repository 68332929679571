import { Roles } from 'src/app/core/enums/roles.enum';
import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
  {
    label: 'Página inicial',
    icon: 'home_outline',
    link: '/main'
  },
  {
    label: 'Atendimento',
    icon: 'contact_phone',
    link: '/atendimento',
    rules: [Roles.ATENDIMENTO_A, Roles.ATENDIMENTO_D_A, Roles.ATENDIMENTO_D_R, Roles.ATENDIMENTO ]
  },
  /*{
    label: 'Bicicletas',
    icon: 'directions_bike',
    link: '/bicicletas',
  },*/
  // {
  //   label: 'Blocos',
  //   icon: 'space_dashboard',
  //   link: '/blocos',
  //   rules: [Roles.BLOCO_I, Roles.BLOCO_R, Roles.BLOCO_W, Roles.BLOCO ]
  // },
  // {
  //   label: 'Câmeras',
  //   icon: 'camera',
  //   link: '/camera',
  //   rules: [Roles.CAMERA_I, Roles.CAMERA_R, Roles.CAMERA_W, Roles.CAMERA ]
  // },
  {
    label: 'Condomínios',
    icon: 'apartment',
    link: '/condominio',
    rules: [Roles.CONDOMINIO_I, Roles.CONDOMINIO_R, Roles.CONDOMINIO_W, Roles.CONDOMINIO ]
  },
  {
    label: 'Configurações',
    icon: 'building',
    link: '/configuracoes',
    rules: [Roles.CONFIGURACAO_R, Roles.CONFIGURACAO_W, Roles.CONFIGURACAO ]
  },
  {
    label: 'Controladoras',
    icon: 'developer_board',
    link: '/placa',
    rules: [Roles.PLACA_I, Roles.PLACA_R, Roles.PLACA_W, Roles.PLACA ]
  },
  {
    label: 'Diretrizes',
    icon: 'devices_other',
    link: '/diretriz',
    rules: [Roles.DIRETRIZ_I, Roles.DIRETRIZ_R, Roles.DIRETRIZ_W, Roles.DIRETRIZ ]
  },
  {
    label: 'Dispositivos',
    icon: 'devices_other',
    link: '/dispositivo',
    rules: [Roles.DISPOSITIVO_I, Roles.DISPOSITIVO_R, Roles.DISPOSITIVO_W, Roles.DISPOSITIVO ]
  },
  {
    label: 'Grupos',
    icon: 'group',
    link: '/grupo',
    rules: [Roles.GRUPO_I, Roles.GRUPO_R, Roles.GRUPO_W, Roles.GRUPO ]
  },
  {
    label: 'Integradora',
    icon: 'admin_panel_settings',
    link: '/integradora',
    rules: [Roles.INTEGRADORA_I, Roles.INTEGRADORA_R, Roles.INTEGRADORA_W, Roles.INTEGRADORA]
  },
  {
    label: 'Moradores',
    icon: 'person_outline',
    link: '/morador',
    rules: [Roles.PESSOA_FISICA_I, Roles.PESSOA_FISICA_R, Roles.PESSOA_FISICA_W, Roles.PESSOA_FISICA ]
  },
  {
    label: 'IP\'s',
    icon: 'shield',
    link: '/ip',
    rules: [Roles.IP_I, Roles.IP_R, Roles.IP_W, Roles.IP ]
  },
  // {
  //   label: 'Identificadores',
  //   icon: 'cast',
  //   link: '/perifericos',
  //   rules: [Roles.PERIFERICO_I, Roles.PERIFERICO_R, Roles.PERIFERICO_W, Roles.PERIFERICO ]
  // },
  /*
  {
    label: 'Leitoras',
    icon: 'fingerprint',
    link: '/leitoras'
  },*//*
  {
    label: 'Mensagens',
    icon: 'message',
    link: '/mensagens'
  },*/
  /*
  {
    label: 'Pets',
    icon: 'pets',
    link: '/pets'
  },*/
  {
    label: 'Relatórios',
    icon: 'insert_drive_file',
  //  link: '/relatorio',
    link: '/relatorio/geral',
    rules: [Roles.RELATORIO_I, Roles.RELATORIO_R, Roles.RELATORIO_W, Roles.RELATORIO ],
    /*subItems: [
         {
           label: 'Geral',
           icon: 'text_snippet',
           link: '/relatorio/geral',
         },
         {
          label: 'Log',
          icon: 'fact_check',
          link: '/relatorio/log',
        },
    ],
  */},/*
  {
    label: 'Restrições',
    icon: 'rule',
    link: '/restricoes',
    rules: [Roles.ROTA_I, Roles.ROTA_R, Roles.ROTA_W, Roles.ROTA ]
  },*/
  {
    label: 'Rotas',
    icon: 'api',
    link: '/rotas',
    rules: [Roles.ROTA_I, Roles.ROTA_R, Roles.ROTA_W, Roles.ROTA ]
  },
  
  {
    label: 'Tipos de atendimento',
    icon: 'settings_phone',
    link: '/tipos-atendimento',
    rules: [Roles.ATENDIMENTO_TIPO_R, Roles.ATENDIMENTO_TIPO_W, Roles.ATENDIMENTO_TIPO ]
  },/*
  {
    label: 'Tipos de pessoa física',
    icon: 'manage_accounts',
    link: '/tipos-pessoa',
    rules: [Roles.PESSOA_FISICA_TIPO_I, Roles.PESSOA_FISICA_TIPO_R, Roles.PESSOA_FISICA_TIPO_W, Roles.PESSOA_FISICA_TIPO ]
  },
  {
    label: 'Tipos de terceiros',
    icon: 'engineering',
    link: '/tipos-terceiros',
    rules: [Roles.TERCEIRO_TIPO_I, Roles.TERCEIRO_TIPO_R, Roles.TERCEIRO_TIPO_W, Roles.TERCEIRO_TIPO ]
  },*/
  {
    label: 'Tipos de unidades',
    icon: 'other_houses',
    link: '/tipos-unidades',
    rules: [Roles.UNIDADE_TIPO_I, Roles.UNIDADE_TIPO_R, Roles.UNIDADE_TIPO_W, Roles.UNIDADE_TIPO]
  },
  {
    label: 'Unidades',
    icon: 'maps_home_work',
    link: '/unidade',
    rules: [Roles.UNIDADE, Roles.UNIDADE_R, Roles.UNIDADE_W, Roles.UNIDADE_I]
  },
  {
    label: 'Usuários',
    icon: 'person',
    link: '/usuintegradora',
    rules: [Roles.USUARIO_I, Roles.USUARIO_R, Roles.USUARIO_W, Roles.USUARIO ]
  },
  // {
  //   label: 'Veículos',
  //   icon: 'directions_car',
  //   link: '/veiculo',
  //   rules: [Roles.VEICULO_I, Roles.VEICULO_R, Roles.VEICULO_W, Roles.VEICULO ]
  // },
  {
    label: 'Visitantes',
    icon: 'engineering',
    link: '/visitantes',
    rules: [Roles.TERCEIRO_I, Roles.TERCEIRO_R, Roles.TERCEIRO_W, Roles.TERCEIRO ]
  },
];
