import { Component, OnInit, Output, Input, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Subscription, Observable, timer } from 'rxjs';

@Component({
  selector: 'app-dataheader',
  templateUrl: './dataheader.component.html',
  styleUrls: ['./dataheader.component.scss']
})
export class DataheaderComponent implements OnInit {


  data: number = Date.now();

  private subscription: Subscription;

	everySecond: Observable<number> = timer(0, 1000);

	constructor(private ref: ChangeDetectorRef) {
	}

	ngOnInit() {
		this.subscription = this.everySecond.subscribe(() => {
      this.data = Date.now();
		})
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

}
