import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Observable, Subject } from 'rxjs';
import { ContentDTO } from 'src/app/core/models/ContentDTO';
import { HttpService } from 'src/app/core/services/http.service';
import { MensagemService } from 'src/app/core/services/mensagem.service';

@Component({
  selector: 'campo-tipo-unidade',
  templateUrl: './campo-tipo-unidade.component.html',
  styleUrls: ['./campo-tipo-unidade.component.scss']
})
export class CampoTipoUnidadeComponent implements OnInit {

  campo: string = 'tipo de unidade';
  controlname: string = 'tipoUnidade';
  searchable: boolean = true;
  clearable: boolean = false;
  @Input() carregamentoInicial: boolean = false;

  @Input() formGroupName: string;
  @Input() endPointPsq: string;
  @Input() endPointFindById: string;
  @Input() extraParams: string;
  @Output() campoEmit = new EventEmitter<any>();
  @Output() campoInicializado = new EventEmitter<boolean>();
  @ViewChild(NgSelectComponent) ngSelect: NgSelectComponent;
  itemsFixo : ContentDTO[];

  form: FormGroup;
  items$: Observable<any>;
  minimoCaracteresBusca = 2;
  pesquisando = false;
  searchInput$ = new Subject<string>();
  idSelecionado: string;
  idIni: string;

  constructor(private httpService: HttpService,
    private rootFormGroup: FormGroupDirective,
    private msg: MensagemService,) { }

  ngOnInit(): void {
    this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
    this.getData().subscribe(
      data => {
        this.itemsFixo = data as ContentDTO[];
        if(this.carregamentoInicial && this.itemsFixo.length > 0){
          this.idSelecionado = this.itemsFixo[0].id;
        }
      }
    )
  }
  
  ngAfterViewInit() {
    setTimeout(() => {
      this.campoInicializado.emit(true);
    }, 500);
  }

  trackByFn(item: ContentDTO) {
    return item.id;
  }

  getData(): Observable<any> {
    return this.httpService.requestAC(this.endPointPsq, null, this.extraParams || '', null);
  }

  onChange() {
    this.campoEmit.emit({ id: this.idSelecionado });
  }

  clear() {
    this.ngSelect.clearModel();
  }

  setSelectedId(id: number) {
    const idSel = this.ngSelect.items.find(x => x.id === id);
    if (idSel) {
      this.idSelecionado = idSel.id;
      this.onChange();
    }
  }
}
