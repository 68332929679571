import { CEPPipe } from './CEPPipe';
import { CpfPipe } from './CPFPipe.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CNPJPipe } from './CNPJPipe';



@NgModule({
  declarations: [CNPJPipe, CpfPipe, CEPPipe],
  imports: [
    CommonModule
  ],
  exports: [
    CNPJPipe, 
    CpfPipe,
    CEPPipe,
  ]
})
export class UtilModule { }
