import { CamposModule } from './../able-pages/campos/campos.module';
import { MatDialogModule } from '@angular/material/dialog';
import { CustomFormsModule } from 'ngx-custom-validators';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';

import { BaseComponent } from './base/base.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { FooterComponent } from './footer/footer.component';

import { ContentAnimateDirective } from '../../core/content-animate/content-animate.directive';

import { NgbDropdownModule, NgbCollapseModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';

//import { FeahterIconModule } from '../../core/feather-icon/feather-icon.module';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { DataheaderComponent } from './dataheader/dataheader.component';
import { CopyrightComponent } from './copyright/copyright.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSortModule } from '@angular/material/sort';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AlterarSenhaComponent } from './navbar/alterar-senha/alterar-senha.component';
import { DadosUsuarioComponent } from './navbar/dados-usuario/dados-usuario.component';
import { MatButtonModule } from '@angular/material/button';
import { TrocarIntegradoraComponent } from './navbar/trocar-integradora/trocar-integradora.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};


@NgModule({
  declarations: [
    BaseComponent, 
    NavbarComponent, 
    SidebarComponent, 
    FooterComponent, 
    ContentAnimateDirective, 
    DataheaderComponent, 
    CopyrightComponent, 
    AlterarSenhaComponent, DadosUsuarioComponent, TrocarIntegradoraComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    NgbDropdownModule,
    NgbCollapseModule,
    PerfectScrollbarModule,
    //FeahterIconModule,
    MatIconModule,
    MatTableModule,
    MatDialogModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MatFormFieldModule,
    MatInputModule,
    CustomFormsModule,
    ReactiveFormsModule,
    NgbNavModule,
    MatButtonModule,
    CamposModule
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },    
  ],
  exports: [CopyrightComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  entryComponents: [
    AlterarSenhaComponent
  ]
})
export class LayoutModule { }
