import { CampoIntegradoraComponent } from './../../../able-pages/campos/campo-integradora/campo-integradora.component';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpService } from 'src/app/core/services/http.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { MensagemConfirmaService } from 'src/app/core/services/mensagem-confirma.service';
import { MensagemService } from 'src/app/core/services/mensagem.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-trocar-integradora',
  templateUrl: './trocar-integradora.component.html',
  styleUrls: ['./trocar-integradora.component.scss']
})
export class TrocarIntegradoraComponent implements OnInit, AfterViewInit {
  
  form: FormGroup;

  @ViewChild(CampoIntegradoraComponent)
  private campoIntegradora!: CampoIntegradoraComponent;


  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private msgConfirma: MensagemConfirmaService,
    private httpSerice: HttpService,
    private loadingService: LoadingService,
    private msg: MensagemService,
    private router: Router) { }
  
  
  ngAfterViewInit(): void {
    this.setDefault();
  }

  ngOnInit(): void {
    this.carregaForm();
    
  }

  carregaForm() {
    this.form = this.fb.group({
      integradora: this.fb.group({
        integradora: ['', [Validators.required]],
      }),
    });
  }

  setDefault() {
    this.campoIntegradora.setSelectedId(Number(sessionStorage.getItem('iid')));
  }

  cancelar(e) {
    e.preventDefault();

    this.msgConfirma.openConfirmDialog("Deseja realmente cancelar alteração ?", (result: any) => {
      if (result.isConfirmed) {
        this.activeModal.close();
      }
    }, "warning", "Cancelar", "Sim", "Não"
    );
  }

  alterar(e) {
    e.preventDefault();
    this.msgConfirma.openConfirmDialog("Deseja realmente alterar dados ?", (result: any) => {
      if (result.isConfirmed) {
        this.form.disable();
        var spinnerRef = this.loadingService.start("Carregando...");
        const dados = {id: sessionStorage.getItem('id'), integradora: this.form.value['integradora'].integradora };

        this.httpSerice.requestAPI('alterarIntegradora', dados)
          .pipe(finalize(() => {
            this.loadingService.stop(spinnerRef);
            this.form.enable();
          })).subscribe(
            data => {
              this.activeModal.close();
              localStorage.removeItem('ablejwt');
              sessionStorage.removeItem('ablejwt');
              this.router.navigate(['/auth/login']);
            },
            err => {
              this.msg.openSnackBar('erro', err['message'], err['status']);
            }
          );
      }
    }, "question", "Confirmação", "Sim", "Não"
    );
  }
}
