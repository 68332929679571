import { startWith, switchMap, map, catchError, finalize } from 'rxjs/operators';
import { merge, Observable, of as observableOf } from 'rxjs';
import { HttpSearchModel } from 'src/app/core/services/models/http-search-model';
import { AuthService } from 'src/app/core/services/auth.service';
import { HttpSearchService } from 'src/app/core/services/http-search.service';
import { MensagemConfirmaService } from 'src/app/core/services/mensagem-confirma.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MensagemService } from 'src/app/core/services/mensagem.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { HttpService } from 'src/app/core/services/http.service';
import { MatSort } from '@angular/material/sort';
import { Roles } from 'src/app/core/enums/roles.enum';
import { MatTableDataSource } from '@angular/material/table';
import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { DvrsManipulateComponent } from '../dvrs-manipulate/dvrs-manipulate.component';

@Component({
  selector: 'app-dvrs-table',
  templateUrl: './dvrs-table.component.html',
  styleUrls: ['./dvrs-table.component.scss']
})
export class DvrsTableComponent implements OnInit, AfterViewInit {

  @ViewChild('campoPesquisa') campoPesquisa: ElementRef;

  @Input()
  condominio: number;

  @Input()
  tipo = '';
  
  ELEMENT_DATA: any[] = [];
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = [];
  dados: any[] = [];
  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  isPermiteEscrita = this.authService.isAuthorized([Roles.DVR, Roles.DVR_W]);
  isPermiteAtivarInativar = this.authService.isAuthorized([Roles.DVR, Roles.DVR_I]);
  results$: Observable<HttpSearchModel[]> = this.httpSearchService.getSearchResults();

  constructor(
    private httpService: HttpService,
    private loadingService: LoadingService,
    private msg: MensagemService,
    private modalService: NgbModal,
    private msgConfirma: MensagemConfirmaService,
    private httpSearchService: HttpSearchService,
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.displayedColumns = ['id', 'nome', 'endereco', 'porta', 'usuario', 'actions'];
  }

  ngAfterViewInit() {
    this.carregaTable();
    this.cdr.detectChanges();
  }

  carregaTable() {
    let spinnerRef: NgbModalRef;
    merge()
      .pipe(
        startWith({}),
        switchMap(() => {
          spinnerRef = this.loadingService.start();
          this.isLoadingResults = true;
          if (this.condominio) {
            return this.getRepoIssues();
          }
          return new Observable(subscriber => {
            // setTimeout(() => {
              this.loadingService.stop(spinnerRef);
              this.isLoadingResults = false;
              
            //   subscriber.complete();
            // }, 50);
          });
        }),
        map(data => {
          this.loadingService.stop(spinnerRef);
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data['totalElements'];
          this.ELEMENT_DATA = data['content'];
          this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
          return data['content'];
        }),
        catchError(err => {
          this.loadingService.stop(spinnerRef);
          this.isLoadingResults = false;
          this.isRateLimitReached = true;
          this.msg.openSnackBar("erro", err['message'], err['status']);
          return observableOf([]);
        })
      ).subscribe(data => this.dados = data, err => console.log(err) );
  }

  adicionarDVR() {
    const dialogRef = this.modalService.open(
      DvrsManipulateComponent, {size: 'xl', backdrop: 'static', keyboard : false, scrollable: true});
    dialogRef.componentInstance.condominio = this.condominio;
    dialogRef.componentInstance.tipo = 'include';
    dialogRef.result.then((fechou) => {
        this.carregaTable();
    });
  }

  verDVR(idDVR: number, ativo: boolean) {
    if(!ativo) {
      this.msg.openSnackBar('erro', 'DVR inativo!', '404');
      return;
    }
    const dialogRef = this.modalService.open(
      DvrsManipulateComponent, {size: 'xl', backdrop: 'static', keyboard : false, scrollable: true});
    dialogRef.componentInstance.tipo = 'read';
    dialogRef.componentInstance.dvrEditar = idDVR;
    dialogRef.result.then((fechou) => {
      this.carregaTable();
    });
  }

  editarDVR(id: number, ativo: boolean) {
    if(!ativo) {
      this.msg.openSnackBar('erro', 'DVR inativo!', '404');
      return;
    }
    const dialogRef = this.modalService.open(
      DvrsManipulateComponent, {size: 'xl', backdrop: 'static', keyboard : false, scrollable: true});
    dialogRef.componentInstance.tipo = 'edit';
    dialogRef.componentInstance.dvrEditar = id;
    dialogRef.result.then((fechou) => {
      this.carregaTable();
    });
  }

  excluirDVR(idDVR: number) {
    this.msgConfirma.openConfirmDialog('Deseja realmente excluir o DVR?', (result: any) => {
      if (result.isConfirmed) {
        const spinnerRef = this.loadingService.start('Carregando...');
        this.httpService.deleteById('excluirDvr', idDVR)
        .pipe(finalize(() => {
          this.loadingService.stop(spinnerRef);
          this.carregaTable();
        })).subscribe(
          data => {},
          err => {
            this.msg.openSnackBar("erro", err['message'], err['status']);
          }
        );
      }
    },'warning', 'Excluir', 'Sim', 'Não'
    );
  }

  desabilitaAtivacaoInativacao() : boolean {
    return !this.isPermiteAtivarInativar;
  }

  beforeChangeSwitch(ipId : number, isAtivo: boolean) : Observable<boolean> {
    return new Observable((observer) => {
      this.msgConfirma.openConfirmDialog(
        isAtivo ? 'Deseja realmente inativar o DVR?' : 'Deseja realmente ativar o DVR?', (result: any) => {
        if (result.isConfirmed) {
          const spinnerRef = this.loadingService.start('Carregando...');
          this.httpService.patchById(isAtivo ? 'inativarDvr' : 'ativarDvr', ipId)
          .pipe(finalize(() => {
            this.loadingService.stop(spinnerRef);
          })).subscribe(
            data => {
              observer.next(true);
              this.carregaTable();
            },
            err => {
              this.msg.openSnackBar("erro", err['message'], err['status']);
              observer.next(false);
            }
          );
        }
      },'warning', isAtivo? 'Inativar' : 'Ativar', 'Sim', 'Não'
    );
    });
  }

  getRepoIssues(): Observable<any> {
    const params = `condominio=${this.condominio}`;
    return this.httpService.requestAPI('psqDvr', null, params, '');
  }

}

